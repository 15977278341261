<script setup lang="ts">
import type { TagSize } from '@/types/tag';

const props = defineProps({
	status: {
		type: String,
		default: null,
	},
	size: {
		type: String as PropType<TagSize>,
		default: TagSizes[2],
	},
});

const displayEventStatus = computed(() => {
	switch (props.status) {
		case EVENT_STATUS.DRAFT:
			return mappedEventStatus[EVENT_STATUS.DRAFT];
		case EVENT_STATUS.OPEN:
			return mappedEventStatus[EVENT_STATUS.OPEN];
		case EVENT_STATUS.FINISHED:
			return mappedEventStatus[EVENT_STATUS.FINISHED];
		case EVENT_STATUS.CANCELED:
			return mappedEventStatus[EVENT_STATUS.CANCELED];
		default:
			return '';
	}
});
</script>
<template>
  <BaseTag
    v-if="displayEventStatus"
    :size="size"
  >
    <div :class="['event-status', status]" />
    {{ displayEventStatus }}
  </BaseTag>
</template>
<style lang="scss" scoped>
.event-status {
    margin-right: rem(6);
    width: rem(8);
    height: rem(8);
    @include border-radius-rounded;

    &.draft {
      background-color: colors-get(warning, 300);
    }

    &.open {
      background-color: colors-get(success, 400);
    }

    &.canceled {
      background-color: colors-get(gray, 300);
    }

    &.finished {
      background-color: colors-get(error, 400);
    }
  }
</style>