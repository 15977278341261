<script setup lang="ts">
import type { TagSize } from '@/types/tag';

const props = defineProps({
	size: {
		type: String as PropType<TagSize>,
		default: TagSizes[2],
	},
	canDelete: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{
	(e: 'onDelete'): void
}>();

const sizeClass = computed(() => {
	if (!TagSizes.includes(props.size)) {
		throw new TypeError(`Invalid size prop: '${props.size}'. Allowed values are ${TagSizes.join(', ')}.`);
	}

	return `size-${props.size}`;
});

</script>
<template>
  <div :class="['tag', sizeClass]">
    <slot />
  </div>
</template>
<style scoped lang="scss">
.tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: colors-get(base, white);
    border: 1px solid colors-get(gray, 300);
    border-radius: 6px;
    color: colors-get(gray, 700);

    &.size-sm {
      @include fonts-get(medium, 'text-xs');
      padding: rem(3) spacings-get(2);
    }

    &.size-md {
      @include fonts-get(medium, 'text-sm');
      padding: rem(2) spacings-get(9);
    }

    &.size-lg {
      @include fonts-get(medium, 'text-sm');
      padding: spacings-get(1) rem(10);
    }

    &.size-xl {
      @include fonts-get(medium, 'text-sm');
      padding: spacings-get(2) spacings-get(3);
    }
}
</style>