<script setup lang="ts">
const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	persistent: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{
	(e: 'onClose'): void
	(e: 'onConfirm'): void
}>();

const isShowValue = computed(() => props.isShow);
</script>

<template>
  <BaseDialog
    :is-show="isShowValue"
    :persistent="persistent"
    hide-close-icon
    @on-close="$emit('onClose')"
  >
    <template #content>
      <div class="spinner-content-container">
        <BaseProgressCircular
          indeterminate
          color="#444CE7"
        />
        <div class="spinner-content">
          <h1 class="dialog-title text-lg text-semibold">
            <slot name="title" />
          </h1>
          <div class="dialog-content text-md">
            <slot name="content" />
          </div>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.dialog-title {
  color: colors-get(gray, 900);
}

.dialog-content {
  color: colors-get(gray, 600);
}

.spinner-content-container {
  display: flex;
  flex-direction: column;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;

  .spinner-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  @include media-query-max(mobile) {
    flex-direction: column-reverse;
  }
}
</style>
