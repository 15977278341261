<script setup lang="ts">
import defaultImageUrl from '@/assets/svg/user-circle.svg';
import type { AvatarSize } from '@/types/avatar';

const props = defineProps({
	imageUrl: {
		type: String as PropType<string | null>,
		default: null,
	},
	alt: {
		type: String,
		default: null,
	},
	size: {
		type: String as PropType<AvatarSize>,
		default: AvatarSizes[2],
	},
});
const isImageError = ref(false);

const displayedImageUrl = computed(() => (!isImageError.value && props.imageUrl ? props.imageUrl : defaultImageUrl));

function handleImageError() {
	isImageError.value = true;
}

const sizeClass = computed(() => {
	if (!AvatarSizes.includes(props.size)) {
		throw new TypeError(`Invalid size prop: '${props.size}'. Allowed values are ${AvatarSizes.join(', ')}.`);
	}

	return `size-${props.size}`;
});
</script>

<template>
  <div :class="['avatar', sizeClass]">
    <img
      class="w-full"
      :src="displayedImageUrl"
      :alt="alt"
      @error="handleImageError"
    >
  </div>
</template>
<style lang="scss" scoped>
.avatar {
	aspect-ratio: 1;

	&.size-xs {
		height: rem(24);
	}

	&.size-sm {
		height: rem(32);
	}

	&.size-md {
		height: rem(40);
	}

	&.size-lg {
		height: rem(48);
	}

	&.size-xl {
		height: rem(56);
	}

	&.size-2xl {
		height: rem(64);
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		@include border-radius-rounded;
	}
}
</style>
