<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.5482 10.5067L13.7148 9.29549L8.00483 1H1.52537L10.1168 13.4833L10.9502 14.6932L17.0205 23.5124H23.5L14.5482 10.5067ZM17.7906 22.0466L11.938 13.5433L11.1046 12.3329L4.31331 2.46564H7.2346L12.7268 10.4454L13.5602 11.6558L20.7119 22.0464L17.7906 22.0466Z"
      :fill="iconColor"
    />
    <path
      d="M11.1046 12.3329L11.938 13.5433L10.9502 14.6932L3.36937 23.5122H1.5L10.1168 13.4833L11.1046 12.3329Z"
      :fill="iconColor"
    />
    <path
      d="M22.7198 1L14.5482 10.5067L13.5602 11.6558L12.7268 10.4454L13.7148 9.29549L19.2501 2.8527L20.8504 1H22.7198Z"
      :fill="iconColor"
    />
  </svg>
</template>