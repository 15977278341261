<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="menu-01">
      <path
        id="Icon"
        d="M2.5 10H17.5M2.5 5H17.5M2.5 15H17.5"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
