<script setup lang="ts">
import type { AuthenticationType } from '@/types/auth';
import type { RouteRecordName } from 'vue-router';

defineProps({
	type: {
		type: Number as PropType<AuthenticationType>,
		default: AUTH_FORM.SIGN_IN,
	},
});

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

watch(
	() => authStore.isLoggedIn,
	async (newValue) => {
		// Redirect to target page after login if the url contain query string 'redirect'
		if (newValue && route.query.redirect) {
			const newRoute = route.query.redirect as RouteRecordName;
			router.push({
				name: newRoute,
			});
		} else if (newValue) {
			router.replace(ROUTE.INDEX.PATH);
		}
	},
);
</script>
<template>
  <div class="signin-page-container">
    <AuthenticationContainerForm
      :type="type"
      :is-loading="authStore.isLoading"
    />
  </div>
</template>
<style scoped lang="scss">
.signin-page-container {
	@include media-query-max(mobile) {
    padding: spacings-get(12) spacings-get(4) 0;
  }
}
</style>