export function useWindowResize() {
	const width = ref(window.innerWidth);
	const height = ref(window.innerHeight);

	function onResize() {
		width.value = window.innerWidth;
		height.value = window.innerHeight;
	}

	const isMobile = computed(() => width.value <= SCREEN_SIZE.MOBILE);

	const isTablet = computed(() => width.value <= SCREEN_SIZE.TABLET);

	// Add resize event listener when the component is mounted
	onMounted(() => {
		window.addEventListener('resize', onResize);
	});

	// Remove the event listener when the component is unmounted
	onUnmounted(() => {
		window.removeEventListener('resize', onResize);
	});

	return {
		width,
		height,
		isMobile,
		isTablet,
	};
}
