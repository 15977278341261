import type { ErrorResponse } from '@/types/common';
import type {
	StripeAccountResponse,
	StripeLinkResponse,
	OnboardingData,
} from '@/types/connect';

export const postOnboardingStripeAccount = (userId: number, onboardingData: OnboardingData) => {
	return useRequest<StripeLinkResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/connect-onboarding`, REQUEST_METHOD.POST, onboardingData);
};

export const deleteDisconnectStripeAccount = (userId: number) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/connect`, REQUEST_METHOD.DELETE);
};

export const getStripeAccountInfoByUserId = (userId: number) => {
	return useRequest<StripeAccountResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/connect`, REQUEST_METHOD.GET, {}, {
		expand: 'requirements',
	});
};