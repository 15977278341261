export enum CHIP_COLOR {
	PRIMARY = 'primary',
	GRAY = 'gray',
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
}

export enum CHIP_SIZE {
	EXTRA_SMALL = 'x-small',
	SMALL = 'small',
	DEFAULT = 'default',
	LARGE = 'large',
	EXTRA_LARGE = 'x-large',
}

export enum CHIP_DENSITY {
	COMFORTABLE = 'comfortable',
	DEFAULT = 'default',
	COMPACT = 'compact',
	EXTRA_LARGE = 'x-large',
}