<script setup lang="ts">

const props = defineProps({
	width: {
		type: String,
		default: '16',
	},
	height: {
		type: String,
		default: '16',
	},
	direction: {
		type: String,
		default: '',
	},
});

const arrowColors = computed(() => {
	if (props.direction === 'up') {
		return {
			top: '#667085',
			bottom: '#D0D5DD',
		};
	} else if (props.direction === 'down') {
		return {
			top: '#D0D5DD',
			bottom: '#667085',
		};
	}
	return {
		top: '#D0D5DD',
		bottom: '#D0D5DD',
	};
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.13398 2.5C7.51888 1.83333 8.48113 1.83333 8.86603 2.5L10.5981 5.5C10.983 6.16667 10.5019 7 9.73205 7H6.26795C5.49815 7 5.01702 6.16667 5.40192 5.5L7.13398 2.5Z"
      :fill="arrowColors.top"
    />
    <path
      d="M8.86603 13.5C8.48112 14.1667 7.51887 14.1667 7.13397 13.5L5.40192 10.5C5.01702 9.83333 5.49815 9 6.26795 9L9.73205 9C10.5019 9 10.983 9.83333 10.5981 10.5L8.86603 13.5Z"
      :fill="arrowColors.bottom"
    />
  </svg>
</template>