import type {
	UserProfile,
	ProfileResponse,
	UserEventResponse,
	UserEventInfo,
	TicketDetailResponse,
	TicketInfo,
} from '@/types/profile';
import type { TicketReceiptInfo, TicketReceiptResponse } from '@/types/ticket';
import dayjs from 'dayjs';

export const transformUser = (data: ProfileResponse): UserProfile => {
	return {
		...data,
		name: data.nickname,
		avatarUrl: data.avatar || '',
		avatarLargeUrl: data.avatar_large || '',
		countryId: data.country_of_residence?.id ?? '',
		timezone: data.timezone_id,
		emergencyContact: data.emergency_contact,
	};
};

const formatUserEventDisplayDate = (startDateTime: string, timezone: string) => {
	const defaultFormat = 'MMM D YYYY';

	// Show default date format e.g. Tue, Oct 13
	return convertDateTimeWithStrictTimezone(startDateTime, timezone, `ddd, ${defaultFormat}`);
};

const derivedEventSoldoutStatus = (data: UserEventResponse) => {
	// Derived final status on sold_out event
	if (data.status !== EVENT_STATUS.SOLD_OUT) {
		return data.status;
	}
	const isPublish = dayjs().isBefore(data.end.utc);
	const isSoldOutBeforeFinished = data.status === EVENT_STATUS.SOLD_OUT && isPublish;

	if (isSoldOutBeforeFinished) {
		return EVENT_STATUS.OPEN;
	}
	return EVENT_STATUS.FINISHED;
};

export const transformUserEventAttending = (data: UserEventResponse): UserEventInfo => {
	const { end, start, coverImage, ...newData } = data;

	const startDateTime = convertDateTimeFromUtcToTimezone(
		start.utc,
		start.timezone,
		DATE_TIME_ISO8601_FORMAT,
	);
	const isHappeningNow = calculateHappeningNow(start, end);

	// Format display date time
	const displayDate = formatUserEventDisplayDate(startDateTime, start.timezone);
	const displayTime = convertDateTimeWithStrictTimezone(
		startDateTime,
		start.timezone,
		TIME_12_HOUR_FORMAT,
	);
	const displayTimezone = getTimezoneAbbreviation(start.timezone);

	const status = derivedEventSoldoutStatus(data);

	return {
		...newData,
		status,
		displayDate,
		displayTime,
		displayTimezone,
		isHappeningNow,
		coverImageUrl: coverImage,
	};
};

export const transformUserEventAttendingList = (items: UserEventResponse[]): UserEventInfo[] => items.map(transformUserEventAttending);

export const transformUserEventHosting = (data: UserEventResponse): UserEventInfo => {
	const { end, start, coverImage, ...newData } = data;

	const startDateTime = convertDateTimeFromUtcToTimezone(
		start.utc,
		start.timezone,
		DATE_TIME_ISO8601_FORMAT,
	);
	const isHappeningNow = calculateHappeningNow(start, end);

	// Format display date time
	const displayDate = formatUserEventDisplayDate(startDateTime, start.timezone);
	const displayTime = convertDateTimeWithStrictTimezone(
		startDateTime,
		start.timezone,
		TIME_12_HOUR_FORMAT,
	);
	const displayTimezone = getTimezoneAbbreviation(start.timezone);

	const status = derivedEventSoldoutStatus(data);

	return {
		...newData,
		status,
		displayDate,
		displayTime,
		displayTimezone,
		isHappeningNow,
		coverImageUrl: coverImage,
	};
};

export const transformUserEventHostingList = (items: UserEventResponse[]): UserEventInfo[] => items.map(transformUserEventHosting);

export const transformTicket = (data: TicketDetailResponse): TicketInfo => {
	const { end, start } = data.event;

	// Calculate happening now
	const isHappeningNow = calculateHappeningNow(start, end);

	const coordinateArray: String[] | null = data.event.locationSettings.venueGeoCoordinates
		? data.event.locationSettings.venueGeoCoordinates.split(',')
		: null;
	const coordinate = coordinateArray
		? {
			lat: Number(coordinateArray[0]),
			lng: Number(coordinateArray[1]),
		}
		: null;

	return {
		...data,
		startDateTime: start.utc,
		endDateTime: end.utc,
		timezone: start.timezone,
		coordinate,
		isHappeningNow,
		qrcode: data.user?.qrcode,
	};
};

export const transformTicketReceipt = (data: TicketReceiptResponse): TicketReceiptInfo => {
	// Show `0` amount for refunded case
	const newAmount = data.status === TICKET_PURCHASE_HISTORY_STATUS.REFUND ? 0 : data.amount;
	return {
		...data,
		amount: formatCurrency(newAmount),
		orderDate: convertDateTimeFromUtcToTimezone(data.orderDate.utc, data.orderDate.timezone),
		issuedDate: convertDateTimeFromUtcToTimezone(data.issuedDate.utc, data.issuedDate.timezone),
	};
};
