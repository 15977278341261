import type { ErrorResponse, FilterInfo } from '@/types/common';
import type {
	ProfileResponse,
	UserGroupResponse,
	GroupUploadFilesResponse,
	UpdatedProfileInfo,
	ProfileInfoResponse,
	ProfileMessageInfo,
	GroupInfo,
	FollowerListResponse,
	SocialProfile,
	GroupInfoResponse,
	FollowingListResponse,
	CountryTimezoneRequest,
	TicketPurchasesHistoryResponse,
	AddCreditCardResponse,
} from '@/types/profile';

export const getMyAccount = () => {
	return useRequest<{ data: { user: ProfileResponse } }>(`${USER_ENDPOINT}/me`, REQUEST_METHOD.GET);
};

export const getUserGroups = (userId: number) => {
	return useRequest<{ data: UserGroupResponse[] }>(`${USER_GROUPS}`, REQUEST_METHOD.GET, {}, {
		ownerId: userId,
		type: GROUP_TYPE.PEASY_GROUP,
	});
};

export const postCreateUserGroup = (groupName: string) => {
	return useRequest<UserGroupResponse>(`${USER_GROUPS}`, REQUEST_METHOD.POST, {
		name: groupName,
		type: GROUP_TYPE.PEASY_GROUP,
	});
};

export const getGroupInfoByGroupId = (groupId: number) => {
	return useRequest<GroupInfo, ErrorResponse>(`${USER_GROUPS}/${groupId}/info`, REQUEST_METHOD.GET);
};

export const postGroupCoverImageAPI = (eventId: number, file: File | Blob) => {
	const formData = new FormData();
	formData.append('coverImage', file || '');

	return useRequest<GroupUploadFilesResponse, ErrorResponse>(
		`${USER_GROUPS}/${eventId}/uploads`,
		REQUEST_METHOD.POST,
		formData,
		{},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
		},
	);
};

export const deleteGroupCoverImageAPI = (eventId: number) => {
	return useRequest<{id: string}, ErrorResponse>(`${USER_GROUPS}/${eventId}/uploads`, REQUEST_METHOD.DELETE);
};

export const patchUpdateGroupInfo = (groupId: number, groupInfo: Partial<GroupInfo>) => {
	return useRequest<GroupInfoResponse, ErrorResponse>(`${USER_GROUPS}/${groupId}`, REQUEST_METHOD.PATCH, groupInfo);
};

// TODO: Implement a new API endpoint for this deprecated API in the future
export const putUpdateProfileInfo = (userId: number, profileInfo: UpdatedProfileInfo) => {
	const formData = new FormData();
	formData.append('nickname', profileInfo.name || '');
	formData.append('avatar_file', profileInfo.file || '');

	return useRequest<{ data: { user: ProfileInfoResponse }}, ErrorResponse>(
		`${USER_ENDPOINT}/${userId}`,
		REQUEST_METHOD.PUT,
		formData,
		{},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
		},
	);
};

export const postMessageToFollower = (groupId: number, messageInfo: ProfileMessageInfo) => {
	return useRequest<{}, ErrorResponse>(`${USER_GROUPS}/${groupId}/messages`, REQUEST_METHOD.POST, messageInfo);
};

export const getFollowerListByUserId = (userGroupId: number, filter: FilterInfo) => {
	return useRequest<FollowerListResponse, ErrorResponse>(`${USER_GROUPS}/${userGroupId}/followers`, REQUEST_METHOD.GET, {}, filter);
};

export const getFollowingListByUserId = (userId: number, filter: FilterInfo) => {
	return useRequest<FollowingListResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/groups`, REQUEST_METHOD.GET, {}, filter);
};

export const getSocialProfileByUserId = (userId: number) => {
	return useRequest<{ data: SocialProfile[] }, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/sns-profiles`, REQUEST_METHOD.GET);
};

export const putUpdateSocialProfile = (userId: number, socialProfile: SocialProfile[]) => {
	return useRequest<{ data: SocialProfile[] }, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/sns-profiles`, REQUEST_METHOD.PUT, {
		data: socialProfile,
	});
};

export const patchUpdateProfileEmail = (userId: number, email: string, identityToken: string) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/profile`, REQUEST_METHOD.PATCH, {
		email,
	}, {}, {
		headers: {
			'X-Peatix-Identity-Validation-Token': identityToken,
		},
	});
};

export const patchUpdateProfileCountryTimezone = (userId: number, payload: CountryTimezoneRequest) => {
	return useRequest<ProfileResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/profile`, REQUEST_METHOD.PATCH, payload);
};

export const getTicketPurchasesHistory = (filterInfo?: FilterInfo) => {
	const filter = {
		...(filterInfo?.sortBy && {sortBy: filterInfo?.sortBy}),
		...(filterInfo?.itemPerPage && {itemPerPage: filterInfo?.itemPerPage}),
		...(filterInfo?.currentPage && {currentPage: filterInfo?.currentPage}),
	};
	return useRequest<TicketPurchasesHistoryResponse, ErrorResponse>(`${USERS_ENDPOINT}/me/account/ticket-purchases`, REQUEST_METHOD.GET, {}, filter);
};

export const postAddCreditCard = () => {
	return useRequest<AddCreditCardResponse, ErrorResponse>(`${USERS_ENDPOINT}/me/account/payment-cards`, REQUEST_METHOD.POST);
};