import { useGtm as useGtmInstance, type TrackEventOptions } from '@gtm-support/vue-gtm';

export function useGtm() {
	const gtm = useGtmInstance();

	// Load UTM parameters from session storage
	const storedUtmValues = getSessionStorage(SESSION_STORAGE_ITEMS.UTM);
	const utmValues = storedUtmValues ?? {};

	const trackEvent = (eventData?: TrackEventOptions) => {
		if (gtm) {
			gtm.trackEvent({
				...eventData,
				...utmValues,
			});
		} else {
			console.warn('GTM is not initialized');
		}
	};

	return {
		trackEvent,
	};
}