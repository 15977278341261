import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

export const HTTP_STATUS_CODE = {
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	NOT_FOUND: 404,
};

export const apiClient = axios.create({
	baseURL: import.meta.env.VITE_BASE_API_URL,
	timeout: 180000, // 3 mins
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

const interceptorRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	const token = getLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
	if (token && config && config.headers) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	// For identifying the Peasy request
	config.headers['X-Peatix-App-Token'] = import.meta.env.VITE_PEATIX_TOKEN;
	return config;
};

const interceptorRequestError = (error: AxiosError) => {
	return Promise.reject(error);
};

const interceptorResponse = (response: AxiosResponse): AxiosResponse => {
	return response;
};

const interceptorResponseError = async (error: AxiosError): Promise<AxiosError | null> => {
	const { status, data } = (error.response as AxiosResponse) ?? {};
	const errorResult = ref<AxiosError | null>(null);
	const subdomain = import.meta.env.VITE_SUBDOMAIN;
	const authStore = useAuthStore();
	switch (status) {
		case HTTP_STATUS_CODE.NOT_FOUND:
			window.location.href = `${subdomain}${ROUTE.ERROR_400.PATH}`;
			break;
		case HTTP_STATUS_CODE.UNAUTHORIZED:
			// Existing email case will be handle on page
			if (data.metadata?.email_exists) {
				errorResult.value = error;
			} else {
				authStore.removeUser(); // Clear localStorage to prevent redirect loop
				window.location.href = `${subdomain}${ROUTE.ERROR_401.PATH}`;
			}
			break;
		default:
			errorResult.value = error;
			break;
	}
	return Promise.reject(errorResult.value);
};

apiClient.interceptors.request.use(interceptorRequest, interceptorRequestError);
apiClient.interceptors.response.use(interceptorResponse, interceptorResponseError);