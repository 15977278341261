export const MAX_EVENT_RECURRING_DAILY = 31;
export const MAX_EVENT_RECURRING_WEEKLY = 52;
export const MAX_EVENT_RECURRING_BI_WEEKLY = 26;
export const MAX_EVENT_RECURRING_MONTHLY = 12;
export const MAX_EVENT_RECURRING_ANNUALLY = 5;

export enum EVENT_TYPE {
	PHYSICAL = 'physical',
	ONLINE = 'online',
}

export enum EVENT_STATUS {
	DRAFT = 'draft',
	OPEN = 'open',
	FINISHED = 'finished',
	CANCELED = 'canceled',
	DELETED = 'deleted',
	SOLD_OUT = 'sold_out',
}

export enum EVENT_TICKET_STATUS {
	ON_SALE = 'on_sale',
	ON_HOLD = 'on_hold',
	DELETED = 'deleted',
	SOLD_OUT = 'sold_out',
	SUSPENDED = 'suspended',
	OPEN = 'open',
}

export enum EVENT_MODAL_NAME {
	EDIT = 'edit',
	PAUSE = 'pause',
	RESUME = 'resume',
	DELETE = 'delete',
}

export enum EVENT_CALENDAR_TYPE {
	GOOGLE = 'Google',
	ICAL = 'iCal',
}

export enum EVENT_RECURRING_TYPE {
	ONCE = 'once',
	DAILY = 'daily',
	WEEKLY = 'weekly',
	BI_WEEKLY = 'bi_weekly',
	MONTHLY = 'monthly',
	MONTHLY_WEEKDAY = 'monthly_weekday',
	ANNUALLY = 'annually',
}