<script setup lang="ts">
defineProps({
	width: {
		type: String,
		default: '',
	},
	height: {
		type: String,
		default: '40',
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const slots = defineSlots();
const hasSlotContent = computed(() => !!slots.default);
</script>

<template>
  <template v-if="hasSlotContent">
    <VSkeletonLoader
      class="button-loader"
      type="button"
      :loading="loading"
      :width="width"
      :height="height"
    >
      <slot />
    </VSkeletonLoader>
  </template>
  <template v-else>
    <VSkeletonLoader
      class="button-loader"
      type="button"
      :loading="loading"
      :width="width"
      :height="height"
    />
  </template>
</template>

<style scoped lang="scss">
.button-loader {
  :deep(.v-skeleton-loader__bone) {
    height: inherit;
    max-width: inherit;
    margin: 0;
  }
}
</style>
