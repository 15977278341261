export enum TABLE_SORT_DIRECTION {
	ASC = '$sortAsc',
	DESC = '$sortDesc',
}

export enum SORT_DIRECTION {
	ASC = 'asc',
	DESC = 'desc',
}

export enum ALIGNMENT {
	START = 'start',
	END = 'end',
	CENTER = 'center',
}

export enum ENVIRONMENT {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum ARROW_ICON_DIRECTION {
	UP = 'up',
	DOWN = 'down',
}