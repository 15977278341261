<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '16',
	},
	height: {
		type: String,
		default: '16',
	},
	strokeWidth: {
		type: String,
		default: '2',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="check-verified-01"
      clip-path="url(#clip0_10021_70702)"
    >
      <path
        id="Icon"
        d="M5.99967 8L7.33301 9.33333L10.333 6.33333M4.88885 2.5458C5.42474 2.50303 5.93348 2.2923 6.34265 1.94361C7.29749 1.12991 8.70186 1.12991 9.6567 1.94361C10.0659 2.2923 10.5746 2.50303 11.1105 2.5458C12.361 2.64559 13.3541 3.63863 13.4539 4.88918C13.4966 5.42507 13.7074 5.93381 14.0561 6.34298C14.8698 7.29781 14.8698 8.70218 14.0561 9.65702C13.7074 10.0662 13.4966 10.5749 13.4539 11.1108C13.3541 12.3614 12.361 13.3544 11.1105 13.4542C10.5746 13.497 10.0659 13.7077 9.6567 14.0564C8.70186 14.8701 7.29749 14.8701 6.34265 14.0564C5.93348 13.7077 5.42474 13.497 4.88885 13.4542C3.6383 13.3544 2.64527 12.3614 2.54547 11.1108C2.50271 10.5749 2.29198 10.0662 1.94329 9.65702C1.12958 8.70218 1.12958 7.29781 1.94329 6.34298C2.29198 5.93381 2.50271 5.42507 2.54547 4.88918C2.64527 3.63863 3.6383 2.64559 4.88885 2.5458Z"
        :stroke="iconColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10021_70702">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
