<script setup lang="ts">
defineProps({
	actionCount: {
		type: Number,
		default: 3,
	},
});

const { isMobile } = useWindowResize();
</script>
<template>
  <div
    class="loader-profile-details"
  >
    <SentencesSkeletonLoader :text-align="ALIGNMENT.CENTER" />
    <div class="loader-buttons-wrapper">
      <ButtonSkeletonLoader
        v-for="item in 3"
        :key="item"
        class="loader-social-button"
        width="20"
        height="20"
      />
    </div>
    <VSkeletonLoader
      class="loader-following"
      type="text"
      width="120"
    />
    <div class="loader-buttons-wrapper">
      <ButtonSkeletonLoader
        v-for="item in actionCount"
        :key="item"
        :width="isMobile ? '36': '120'"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.loader-profile-details {
	display: grid;
	gap: spacings-get(4);

	.loader-buttons-wrapper {
		display: flex;
		gap: spacings-get(4);
		justify-content: center;

		.loader-social-button {
			:deep(.v-skeleton-loader__bone) {
				@include border-radius-default;
			}
		}
	}

	.loader-following {
		margin: auto
	}
}
</style>