<script setup lang="ts">
import type { StripePaymentMethodResponse } from '@/types/checkout';
import {
	loadStripe,
	type SetupIntentResult,
	type Stripe,
	type StripeElements,
	type StripePaymentElement,
} from '@stripe/stripe-js';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import appearance from '../../plugins/stripe';

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onFail'): void
	(e: 'onSuccess'): void
}>();

const props = defineProps({
	isOpen: {
		type: Boolean,
		default: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
});

const profileStore = useProfileStore();
const route = useRoute();

const stripeCardFormInstance = ref<Stripe | null>(null);
const stripeElements = ref<StripeElements | null>(null);
const cardSetUpElement = ref<StripePaymentElement | null>(null);

const isStripeFormLoaded = ref(false);
const isProcessing = ref(false);
const isInitializing = ref(false);
const cardName = ref('');
const creditCardFormRef = ref();

const rules = {
	cardName: {
		requiredCardNameIf: helpers.withMessage('Please enter the name on your card.', required),
	},
};

const v$ = useVuelidate(rules, { cardName });

const currentFullUrl = computed(() => {
	// Accessing the full path of the current route
	return getFullUrlPath(route.fullPath);
});

function cleanupCardForm() {
	creditCardFormRef.value?.clearCardName();
	cardName.value = '';
	v$.value.$reset();
	isStripeFormLoaded.value = false;
	stripeCardFormInstance.value = null;
	if (cardSetUpElement.value) {
		cardSetUpElement.value.unmount();
	}
}

async function initialize() {
	isInitializing.value = true;
	if (stripeCardFormInstance.value) {
		return;
	}
	// Get Stripe public key and client secret
	await profileStore.addCard();

	if (!profileStore.stripeAddCardKeys?.publicKey) {
		throw new TypeError('Stripe public key does not defined');
	}
	if (!profileStore.stripeAddCardKeys?.clientSecret) {
		throw new TypeError('Stripe client secret does not defined');
	}

	// Init credit card form instance. Use the instance to collect saved credit card on platform's account.
	stripeCardFormInstance.value = await loadStripe(profileStore.stripeAddCardKeys.publicKey);

	if (!stripeCardFormInstance.value) {
		return;
	}
	stripeElements.value = stripeCardFormInstance.value.elements({
		clientSecret: profileStore.stripeAddCardKeys.clientSecret,
		appearance,
		loader: 'never',
	});
	cardSetUpElement.value = stripeElements.value.create('payment', {
		layout: {
			type: 'tabs',
			defaultCollapsed: false,
		},
		wallets: {
			applePay: 'never',
			googlePay: 'never',
		},
	});
	cardSetUpElement.value.mount('#card-element');
	cardSetUpElement.value.on('ready', async () => {
		// Handle ready event
		isStripeFormLoaded.value = true;
		isInitializing.value = false;
	});
}

async function handleCreatePaymentMethod(): Promise<StripePaymentMethodResponse> {
	if (!stripeElements.value) {
		return {};
	}

	// Trigger card name validation
	v$.value.$touch();

	// Trigger new card to stripe to validate form
	const { error: submitError } = await stripeElements.value.submit();
	if (submitError || v$.value.$error) {
		return {
			isError: !!submitError || !!v$.value.$error,
			error: submitError,
		};
	}

	const elements = stripeElements.value;

	if (!profileStore.stripeAddCardKeys?.clientSecret || !stripeCardFormInstance.value || !elements) {
		return {};
	}

	// Create new card
	const { setupIntent, error }: SetupIntentResult = await stripeCardFormInstance.value
		.confirmSetup({
			elements,
			clientSecret: profileStore.stripeAddCardKeys?.clientSecret,
			confirmParams: {
				// Return URL where the customer should be redirected after the SetupIntent is confirmed.
				return_url: `${currentFullUrl.value}?tab=saved_cards`,
			},
			redirect: 'if_required',
		});

	return {
		error,
		setupIntent,
		isCardNameValid: !v$.value.$error,
	};
}

async function handleAddNewCard() {
	isProcessing.value = true;
	const { setupIntent, isCardNameValid, isError } = await handleCreatePaymentMethod();
	let paymentId;
	if (setupIntent && isCardNameValid) {
		paymentId = setupIntent.id;
	} else if (isError) {
		isProcessing.value = false;
		return;
	}

	if (paymentId) {
		emit('onSuccess');
	} else {
		emit('onFail');
	}
	cleanupCardForm();
	isProcessing.value = false;
}

function handleCardNameChange(value: string) {
	cardName.value = value;
}

watch(() => props.isOpen, (newValue, prevValue) => {
	if (newValue && !prevValue) {
		// To open
		initialize();
	} else if (!newValue && prevValue) {
		// To close
		cleanupCardForm();
	}
});

onUnmounted(() => {
	cleanupCardForm();
});
</script>
<template>
  <BaseSideDrawer
    :is-open="isOpen"
    @on-close="emit('onClose')"
  >
    <template #header>
      <div
        class="form-header"
      >
        <CreditCardPlusIcon />
        <h1 class="text-xl text-semibold">
          Add new card
        </h1>
      </div>
    </template>
    <template #content>
      <div class="form-content">
        <div class="new-card-form-wrapper">
          <CreditCardCreateForm
            ref="creditCardFormRef"
            :is-stripe-form-loaded="isStripeFormLoaded"
            :loading="isInitializing"
            :error-card-name-message="getValidationErrorMessage(v$.cardName.$errors)"
            @on-card-name-change="handleCardNameChange"
          >
            <div
              id="card-element"
            />
          </CreditCardCreateForm>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="form-footer">
        <ButtonSkeletonLoader
          v-if="isInitializing"
          width="62"
          height="42"
        />
        <BaseButton
          v-else
          size="md"
          :disabled="isProcessing || isLoading"
          @click="handleAddNewCard"
        >
          Add
        </BaseButton>
      </div>
    </template>
  </BaseSideDrawer>
</template>
<style scoped lang="scss">
.form {
  &-header {
    display: flex;
    align-items: center;
    gap: spacings-get(2)
  }

  &-content {
    display: flex;
    flex-direction: column;

    .new-card-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: spacings-get(4);
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: spacings-get(3);
    height: 100%;
    padding: 0 spacings-get(6);
    color: colors-get(base, white);
    border-top: 1px solid #{colors-get(gray, 200)};
  }
}

.hide {
  display: none !important;
}
</style>