<script setup lang="ts">
const props = defineProps({
	currentEmail: {
		type: String,
		required: true,
	},
	newEmail: {
		type: String,
		required: true,
	},
});

const emit = defineEmits<{
	(e: 'onResendCode'): void
}>();

const authStore = useAuthStore();
const isOpen = ref(false);
const resolver = ref();

async function handleResendCode() {
	emit('onResendCode');
}

async function open() {
	isOpen.value = true;

	// Return promise and only resolve after the component resolve itself
	return new Promise((resolve) => {
		resolver.value = resolve;
	});
}

function handleCancel() {
	isOpen.value = false;
	resolver.value(false);
}

async function handleSubmitCode(code: string) {
	await authStore.verifyAccountByCode(code, props.currentEmail, authStore.identityToken);

	if (authStore.errorMessage) {
		return;
	}

	isOpen.value = false;
	resolver.value(true);
}

defineExpose({
	open,
});

</script>
<template>
  <BaseDialog
    :is-show="isOpen"
    :hide-close-icon="true"
  >
    <template #content>
      <CodeVerificationForm
        class="verify-account"
        :is-loading="false"
        confirm-button-name="Verify"
        :server-error="authStore.errorMessage"
        @on-submit-code="handleSubmitCode"
        @on-resend-code="handleResendCode"
      >
        <template #header>
          <h1
            class="text-semibold text-lg"
          >
            One-time account verification
          </h1>
        </template>
        <template #content>
          <div
            class="text-sm text-align-center text-regular"
          >
            We sent a verification code to <b class="text-semibold">{{ newEmail }}</b>. Please check your email, and enter the code below to verify account. Code expires in 15 minutes.
          </div>
        </template>

        <template #action>
          <BaseButton
            class="full-width"
            variant="subtle"
            color="gray"
            size="lg"
            @click="handleCancel"
          >
            Cancel
          </BaseButton>
        </template>
      </CodeVerificationForm>
    </template>
  </BaseDialog>
</template>