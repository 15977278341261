<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
	strokeWidth: {
		type: String,
		default: '2',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 24.3089L21 30.3089L33 18.3089M44 24.3089C44 35.3546 35.0457 44.3089 24 44.3089C12.9543 44.3089 4 35.3546 4 24.3089C4 13.2632 12.9543 4.3089 24 4.3089C35.0457 4.3089 44 13.2632 44 24.3089Z"
      :stroke="iconColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
