import { type ComputedRef } from 'vue';

export function useIconColor(color: ComputedRef<string>) {
	const iconColor = computed(() => {
		if (!color.value) {
			return ICON_COLOR.CURRENT_COLOR;
		}

		if (color.value === ICON_COLOR.CURRENT_COLOR) {
			return ICON_COLOR.CURRENT_COLOR;
		}

		if (color.value === ICON_COLOR.DEFAULT) {
			return '#344054';
		}

		if (color.value === ICON_COLOR.DISABLED) {
			return '#D0D5DD';
		}

		if (color.value === ICON_COLOR.ERROR) {
			return '#D92D20';
		}

		if (color.value === ICON_COLOR.SUCCESS) {
			return '#079455';
		}

		if (color.value === ICON_COLOR.WARNING) {
			return '#DC6803';
		}

		if (color.value === ICON_COLOR.PRIMARY) {
			return '#444CE7';
		}

		if (color.value === ICON_COLOR.WHITE) {
			return '#FFFFFF';
		}

		return color.value;
	});
	return {
		color: iconColor,
	};
}
