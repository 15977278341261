<script setup lang="ts">
import type { ButtonList } from '@/types/common';

defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
});

const buttonList: ButtonList[] = [{
	name: 'Safari',
	url: 'https://support.apple.com/en-us/102524',
}, {
	name: 'Google Chrome',
	url: 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop&oco=1',
}, {
	name: 'Microsoft Edge',
	url: 'https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5',
}, {
	name: 'Firefox',
	url: 'https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting',
}];

defineEmits<{
	(e: 'onClose'): void
	(e: 'onConfirm'): void
}>();

function handleLinkClick(item: ButtonList) {
	openLink(item.url);
}
</script>
<template>
  <ConfirmDialog
    :is-show="isShow"
    confirm-button-name="Ok, I got it"
    hide-cancel-button
    @on-close="$emit('onClose')"
    @on-confirm="$emit('onConfirm')"
  >
    <template #header>
      <AlertCircleIcon
        class="icon-header-confirm-dlg icon-error"
        :color="ICON_COLOR.ERROR"
      />
    </template>
    <template #title>
      Cannot proceed with Apple because the browser’s pop-up blocker is enabled.
    </template>
    <template #content>
      <div class="content">
        <p class="text-sm text-regular">
          Please allow pop-ups and redirects on your browser in order to continue successfully.
        </p>
        <div class="instruction-list">
          <p class="text-sm text-regular">
            Here are some instructions for each browser:
          </p>
          <template
            v-for="item in buttonList"
            :key="item.name"
          >
            <BaseButton
              class="link"
              variant="link"
              color="primary"
              :href="item.url"
              @click="handleLinkClick(item)"
            >
              {{ item.name }}
            </BaseButton>
          </template>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: spacings-get(4);

    .instruction-list {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: spacings-get(2);

        .link {
            padding: 0;
        }
    }
}
</style>