<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '12',
	},
	height: {
		type: String,
		default: '12',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      d="M5.49912 6.49998C5.71385 6.78705 5.9878 7.02457 6.3024 7.19645C6.61699 7.36833 6.96488 7.47054 7.32245 7.49615C7.68002 7.52176 8.03892 7.47017 8.3748 7.34487C8.71068 7.21958 9.01568 7.02352 9.26912 6.76998L10.7691 5.26998C11.2245 4.79848 11.4765 4.16697 11.4708 3.51148C11.4651 2.85599 11.2022 2.22896 10.7387 1.76544C10.2752 1.30192 9.64812 1.03899 8.99263 1.0333C8.33713 1.0276 7.70563 1.27959 7.23412 1.73498L6.37412 2.58998M7.49912 5.49998C7.2844 5.21292 7.01044 4.97539 6.69585 4.80351C6.38125 4.63163 6.03337 4.52942 5.6758 4.50382C5.31823 4.47821 4.95933 4.5298 4.62345 4.65509C4.28757 4.78038 3.98257 4.97645 3.72912 5.22998L2.22912 6.72998C1.77373 7.20149 1.52174 7.83299 1.52744 8.48849C1.53313 9.14398 1.79606 9.77101 2.25958 10.2345C2.7231 10.698 3.35013 10.961 4.00562 10.9667C4.66111 10.9724 5.29262 10.7204 5.76412 10.265L6.61912 9.40998"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>