<script setup lang="ts">
const { isMobile } = useWindowResize();
</script>
<template>
  <div class="page page-error">
    <div class="message">
      <slot />
    </div>
    <RouterLink :to="{name: ROUTE.INDEX.NAME}">
      <BaseButton :size="isMobile ? BtnSize[3] : BtnSize[4]">
        Return home
      </BaseButton>
    </RouterLink>
  </div>
</template>
<style scoped lang="scss">
.page-error, .page-error .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-error {
  gap: spacings-get(12);
  min-height: inherit;
  justify-content: center;
  text-align: center;

  @include media-query-max(mobile) {
    gap: spacings-get(8);
  }

  .message {
    gap: spacings-get(6);

    @include media-query-max(mobile) {
      gap: spacings-get(4);
    }
  }
}
</style>