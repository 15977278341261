<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
	strokeWidth: {
		type: String,
		default: '1.66667',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check">
      <path
        id="Icon"
        d="M16.6668 5L7.50016 14.1667L3.3335 10"
        :stroke="iconColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
