<template>
  <svg
    width="77"
    height="2"
    viewBox="0 0 77 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector 12"
      d="M1.75 1L75.75 0.999994"
      stroke="#444CE7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="4 4"
    />
  </svg>
</template>