<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8337 17.5003V12.5003M13.3337 15.0003H18.3337M18.3337 8.33366H1.66699M18.3337 10.0003V6.83366C18.3337 5.90024 18.3337 5.43353 18.152 5.07701C17.9922 4.76341 17.7372 4.50844 17.4236 4.34865C17.0671 4.16699 16.6004 4.16699 15.667 4.16699H4.33366C3.40024 4.16699 2.93353 4.16699 2.57701 4.34865C2.2634 4.50844 2.00844 4.7634 1.84865 5.07701C1.66699 5.43353 1.66699 5.90024 1.66699 6.83366V13.167C1.66699 14.1004 1.66699 14.5671 1.84865 14.9236C2.00844 15.2372 2.2634 15.4922 2.57701 15.652C2.93353 15.8337 3.40024 15.8337 4.33366 15.8337H10.0003"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>