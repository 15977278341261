<template>
  <div class="credit-card-form-loader">
    <div>
      <label class="label text-sm text-medium">
        Name on card
      </label>
      <TextInputSkeletonLoader
        height="44"
      />
    </div>
    <div>
      <label class="label text-sm text-medium">
        Card number
      </label>
      <TextInputSkeletonLoader
        height="44"
      />
    </div>
    <div class="expiration-date">
      <div>
        <label class="label text-sm text-medium">
          Expiration
        </label>
        <TextInputSkeletonLoader
          height="44"
        />
      </div>
      <div>
        <label class="label text-sm text-medium">
          CVC
        </label>
        <TextInputSkeletonLoader
          height="44"
        />
      </div>
    </div>
    <div>
      <label class="label text-sm text-medium">
        Country
      </label>
      <TextInputSkeletonLoader
        height="44"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.credit-card-form-loader {
	display: flex;
	flex-direction: column;
	row-gap: spacings-get(4);

	.label {
		color: colors-get(gray, 700);
	}

	.expiration-date {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: spacings-get(3);
	}
}
</style>
