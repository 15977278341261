<script setup lang="ts">
import SettingsIcon from '@/components/icons/SettingsIcon.vue';
import CreditCardIcon from '@/components/icons/CreditCardIcon.vue';
import BankNoteIcon from '@/components/icons/BankNoteIcon.vue';
import NotificationBoxIcon from '@/components/icons/NotificationBoxIcon.vue';
import TicketIcon from '@/components/icons/TicketIcon.vue';
import UsersIcon from '@/components/icons/UsersIcon.vue';
import { isObject } from 'lodash';

const props = defineProps({
	initialTab: {
		type: String,
		default: null,
	},
});

const tabs = [
	{
		title: mappedSettingTabTitle[SETTING_TABS.ACCOUNT_SETTING],
		name: SETTING_TABS.ACCOUNT_SETTING,
		icon: SettingsIcon,
	},
	{
		title: mappedSettingTabTitle[SETTING_TABS.PAYOUTS],
		name: SETTING_TABS.PAYOUTS,
		icon: BankNoteIcon,
	},
	{
		title: mappedSettingTabTitle[SETTING_TABS.SAVED_CARDS],
		name: SETTING_TABS.SAVED_CARDS,
		icon: CreditCardIcon,
	},
	{
		title: mappedSettingTabTitle[SETTING_TABS.NOTIFICATION_SETTINGS],
		name: SETTING_TABS.NOTIFICATION_SETTINGS,
		icon: NotificationBoxIcon,
	},
	{
		title: mappedSettingTabTitle[SETTING_TABS.TICKET_PURCHASE_HISTORY],
		name: SETTING_TABS.TICKET_PURCHASE_HISTORY,
		icon: TicketIcon,
	},
	{
		title: mappedSettingTabTitle[SETTING_TABS.FOLLOWING],
		name: SETTING_TABS.FOLLOWING,
		icon: UsersIcon,
	},
];
const route = useRoute();
const router = useRouter();
const profileStore = useProfileStore();
const { isTablet } = useWindowResize();

const activeTab = ref<string>(props.initialTab || SETTING_TABS.ACCOUNT_SETTING);

function handleDropdownChange(selectedValue: string) {
	activeTab.value = selectedValue;
}

watch(() => props.initialTab, (newTab) => {
	if (newTab) {
		activeTab.value = newTab.toString();
	}
});

onMounted(async () => {
	if (!isObject(route.query) && !profileStore.profileId) {
		await profileStore.myAccountInfo();
	}
	// If the user is redirected from Stripe to the settings page,
	// automatically switch to the payouts tab
	if (route.query?.[ROUTE_QUERY_CONNECT_STRIPE_STATUS]) {
		activeTab.value = SETTING_TABS.PAYOUTS;
		router.push({ query: {} });
	} else if (route.query?.tab === SETTING_TABS.SAVED_CARDS) {
		activeTab.value = SETTING_TABS.SAVED_CARDS;
		router.push({ query: {} });
	}
});
</script>
<template>
  <div class="page settings-page-container">
    <template v-if="isTablet">
      <AccountSettingMobileMenu
        :selected="activeTab"
        :settings="tabs"
        @on-change="handleDropdownChange"
      />
    </template>
    <template v-else>
      <VTabs
        v-model="activeTab"
        class="settings-menu"
        direction="vertical"
        centered
      >
        <VTab
          v-for="tab in tabs"
          :key="tab.name"
          :value="tab.name"
          :ripple="false"
        >
          <component
            :is="tab.icon"
            :color="activeTab === tab.name ? '#3538CD' : '#667085'"
            width="24"
            height="24"
          />
          {{ tab.title }}
        </VTab>
      </VTabs>
    </template>
    <VWindow
      v-model="activeTab"
      class="settings-content"
      direction="vertical"
    >
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.ACCOUNT_SETTING"
      >
        <AccountSettingProfileForm />
      </VWindowItem>
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.PAYOUTS"
      >
        <AccountSettingPayoutForm />
      </VWindowItem>
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.SAVED_CARDS"
      >
        <AccountSettingSavedCardForm />
      </VWindowItem>
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.NOTIFICATION_SETTINGS"
      >
        <AccountSettingNotificationForm />
      </VWindowItem>
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.TICKET_PURCHASE_HISTORY"
      >
        <AccountSettingTicketHistory v-if="activeTab === SETTING_TABS.TICKET_PURCHASE_HISTORY" />
      </VWindowItem>
      <VWindowItem
        :transition="false"
        :reverse-transition="false"
        :value="SETTING_TABS.FOLLOWING"
      >
        <AccountSettingFollowingForm v-if="activeTab === SETTING_TABS.FOLLOWING" />
      </VWindowItem>
    </VWindow>
  </div>
</template>
<style lang="scss" scoped>
.settings-page-container {
  display: flex;
  flex-direction: row;
  gap: spacings-get(8);
  max-width: rem(1200);

  @include media-query-max(tablet) {
    flex-direction: column;
    padding-bottom: spacings-get(12);
    padding-top: 0;
  }

  .settings-menu, .settings-content {
    border: 1px solid colors-get(gray, 200);
    @include border-radius-lg;
  }

  .settings-menu {
    height: fit-content;
    padding: spacings-get(3);
  }

  .settings-content {
    width: 100%;
    height: fit-content;
    padding: spacings-get(6);

    @include media-query-max(tablet) {
      padding: 0 spacings-get(4);
      border: none;
      border-radius: 0;
    }
  }
}
</style>