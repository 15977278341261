<script lang="ts" setup>
defineProps({
	isTransparentBackground: {
		type: Boolean,
		default: false,
		required: true,
	},
});

const authStore = useAuthStore();
const profileStore = useProfileStore();
const router = useRouter();

async function handleSignOut() {
	if (profileStore.profileId) {
		await authStore.logout(profileStore.profileId);
	}

	if (!authStore.errorMessage) {
		router.replace(ROUTE.SIGN_OUT.PATH);
	}
}
</script>

<template>
  <nav>
    <UserMenu
      v-if="authStore.isLoggedIn"
      @on-sign-out="handleSignOut"
    />
    <GuestMenu
      v-else
      :is-transparent-background="isTransparentBackground"
    />
  </nav>
</template>
