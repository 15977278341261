<script setup lang="ts">
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';
import type {
	SETTING_TABS as SETTING_TABS_ENUM,
} from '@/enums/setting';

interface Tab {
	title: String;
	name: SETTING_TABS_ENUM;
	icon: any;
}

const props = defineProps({
	selected: {
		type: String,
		default: null,
		required: true,
	},
	settings: {
		type: Array as PropType<Tab[]>,
		default: () => [],
		required: true,
	},
});

const emit = defineEmits<{
	(e: 'onChange', value: string): void
}>();

const isOpen = ref(false);

const tabIcons = computed(() => props.settings.map((tab) => tab.icon));

const chevronIconComponent = computed(() => (isOpen.value ? ChevronUpIcon : ChevronDownIcon));

const selectedTab = computed({
	get: () => props.selected,
	set: (newValue) => {
		if (newValue) {
			emit('onChange', newValue);
		}
	},
});

const tabIconComponent = computed(() => {
	return props.settings.find((tab) => tab.name === selectedTab.value)?.icon;
});

function mappedSelectTabName(selected: string) {
	switch (selected) {
		case SETTING_TABS.ACCOUNT_SETTING:
			return mappedSettingTabTitle[SETTING_TABS.ACCOUNT_SETTING];
		case SETTING_TABS.PAYOUTS:
			return mappedSettingTabTitle[SETTING_TABS.PAYOUTS];
		case SETTING_TABS.SAVED_CARDS:
			return mappedSettingTabTitle[SETTING_TABS.SAVED_CARDS];
		case SETTING_TABS.NOTIFICATION_SETTINGS:
			return mappedSettingTabTitle[SETTING_TABS.NOTIFICATION_SETTINGS];
		case SETTING_TABS.TICKET_PURCHASE_HISTORY:
			return mappedSettingTabTitle[SETTING_TABS.TICKET_PURCHASE_HISTORY];
		case SETTING_TABS.FOLLOWING:
			return mappedSettingTabTitle[SETTING_TABS.FOLLOWING];
		default:
			return mappedSettingTabTitle[SETTING_TABS.ACCOUNT_SETTING];
	}
}

const displayTabName = computed(() => mappedSelectTabName(props.selected));

async function handleClickInput() {
	isOpen.value = !isOpen.value;
	await nextTick(); // Wait for DOM update
}

function handleSelectMenu(tab: Tab) {
	selectedTab.value = tab.name;
	emit('onChange', tab.name);
	handleClickInput();
}
</script>

<template>
  <div class="mobile-settings-menu">
    <div
      class="mobile-settings-input"
      @click="handleClickInput"
    >
      <component
        :is="tabIconComponent"
        :color="ICON_COLOR.PRIMARY"
        width="24"
        height="24"
      />
      <span class="display-name">{{ displayTabName }}</span>
      <component
        :is="chevronIconComponent"
        :color="ICON_COLOR.PRIMARY"
        width="20"
        height="20"
      />
    </div>
    <Transition>
      <VList
        v-if="isOpen"
        class="mobile-settings-list"
      >
        <VListItem
          v-for="(item, index) in settings"
          :key="index"
          class="list-item"
          :ripple="false"
          @click="handleSelectMenu(item)"
        >
          <div :class="['list-item-inner', {'--selected': selectedTab === item.name}]">
            <span class="list-item-title">
              <component
                :is="tabIcons[index]"
                color="#667085"
                width="24"
                height="24"
              />
              <span class="text-semibold">{{ item.title }}</span>
            </span>
            <CheckIcon
              v-if="(selectedTab as string) === item.name"
              :color="ICON_COLOR.PRIMARY"
            />
          </div>
        </VListItem>
      </VList>
    </Transition>
    <VOverlay
      v-model="isOpen"
      class="mobile-settings-overlay"
    />
  </div>
</template>
<style lang="scss" scoped>
.mobile-settings-menu {
  position: relative;
}

.mobile-settings-input {
  height: rem(40);
  display: flex;
  align-items: center;
  padding: spacings-get(2) spacings-get(4);
  background-color: colors-get(primary, 25);

  .display-name {
    margin-left: spacings-get(3);
    margin-right: auto;
    @include fonts-get(semibold, text-md);
    color: colors-get(primary, 600);
    background-color: colors-get(primary, 25);
  }

  svg:last-child {
    min-width: spacings-get(6);
    min-height: spacings-get(6);
  }
}

.mobile-settings-list {
  position: absolute;
  top: rem(46);
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(1);
  padding-top: 0;
  z-index: 2;

  .list-item {
    height: rem(40);
    min-height: rem(40);
    padding-inline: 0 !important;

    .list-item-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: spacings-get(4);

      .list-item-title {
        display: flex;
        justify-content: flex-start;
        column-gap: spacings-get(3);
        color: colors-get(gray, 700);
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-enter-from, .v-leave-to {
  max-height: 0;
}

.v-enter-to, .v-leave-from {
  max-height: rem(312);
}
</style>
