<script setup lang="ts">
defineProps({
	isStripeFormLoaded: {
		type: Boolean,
		required: true,
		default: false,
	},
	isShowSaveForFuture: {
		type: Boolean,
		default: false,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	errorCardNameMessage: {
		type: String,
		required: true,
		default: '',
	},
});

const emit = defineEmits<{
	(e: 'onSaveCardChange', isSaveCard: boolean): void;
	(e: 'onCardNameChange', name: string): void;
}
>();

const cardNameInput = ref('');
const isSaveCard = ref(true);

function handleCardNameChange() {
	emit('onCardNameChange', cardNameInput.value);
}

function clearCardName() {
	cardNameInput.value = '';
}

watch(isSaveCard, ((newIsSaveCard) => {
	emit('onSaveCardChange', newIsSaveCard);
}));

defineExpose({
	clearCardName,
});
</script>
<template>
  <CreditCardFormSkeletonLoader v-if="loading" />
  <BaseTextInput
    v-if="!loading && isStripeFormLoaded"
    v-model="cardNameInput"
    class="card-name-input"
    label="Name on card"
    :error-message="errorCardNameMessage"
    @change="handleCardNameChange"
  />
  <slot />
  <BaseCheckbox
    v-if="!loading && isStripeFormLoaded && isShowSaveForFuture"
    id="save-card"
    v-model="isSaveCard"
    label="Save card for future purchases"
  />
</template>
<style scoped lang="scss">
.card-name-input {
  color: colors-get(gray, 700);
}
</style>