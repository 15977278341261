<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.66602 5.83398L8.47012 10.5969C9.02109 10.9825 9.29658 11.1754 9.59624 11.2501C9.86093 11.3161 10.1378 11.3161 10.4025 11.2501C10.7021 11.1754 10.9776 10.9825 11.5286 10.5969L18.3327 5.83398M5.66602 16.6673H14.3327C15.7328 16.6673 16.4329 16.6673 16.9677 16.3948C17.4381 16.1552 17.8205 15.7727 18.0602 15.3023C18.3327 14.7675 18.3327 14.0674 18.3327 12.6673V7.33398C18.3327 5.93385 18.3327 5.23379 18.0602 4.69901C17.8205 4.2286 17.4381 3.84615 16.9677 3.60647C16.4329 3.33398 15.7328 3.33398 14.3327 3.33398H5.66602C4.26588 3.33398 3.56582 3.33398 3.03104 3.60647C2.56063 3.84615 2.17818 4.2286 1.9385 4.69901C1.66602 5.23379 1.66602 5.93385 1.66602 7.33398V12.6673C1.66602 14.0674 1.66602 14.7675 1.9385 15.3023C2.17818 15.7727 2.56063 16.1552 3.03104 16.3948C3.56582 16.6673 4.26588 16.6673 5.66602 16.6673Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>