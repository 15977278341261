<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="x-circle"
      clip-path="url(#clip0_2602_35175)"
    >
      <path
        id="Icon"
        d="M10 6.00004L6.00004 10M6.00004 6.00004L10 10M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z"
        :stroke="iconColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2602_35175">
        <rect
          :width="width"
          :height="height"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
