<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M18.8333 7.44265C18.8333 6.9378 18.8333 6.68538 18.7335 6.56849C18.6468 6.46707 18.5169 6.41325 18.3839 6.42371C18.2307 6.43577 18.0522 6.61426 17.6952 6.97124L14.6666 9.99984L17.6952 13.0284C18.0522 13.3854 18.2307 13.5639 18.3839 13.576C18.5169 13.5864 18.6468 13.5326 18.7335 13.4312C18.8333 13.3143 18.8333 13.0619 18.8333 12.557V7.44265Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.16663 8.1665C2.16663 6.76637 2.16663 6.06631 2.43911 5.53153C2.67879 5.06112 3.06124 4.67867 3.53165 4.43899C4.06643 4.1665 4.76649 4.1665 6.16663 4.1665H10.6666C12.0668 4.1665 12.7668 4.1665 13.3016 4.43899C13.772 4.67867 14.1545 5.06112 14.3941 5.53153C14.6666 6.06631 14.6666 6.76637 14.6666 8.1665V11.8332C14.6666 13.2333 14.6666 13.9334 14.3941 14.4681C14.1545 14.9386 13.772 15.321 13.3016 15.5607C12.7668 15.8332 12.0668 15.8332 10.6666 15.8332H6.16663C4.76649 15.8332 4.06643 15.8332 3.53165 15.5607C3.06124 15.321 2.67879 14.9386 2.43911 14.4681C2.16663 13.9334 2.16663 13.2333 2.16663 11.8332V8.1665Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
