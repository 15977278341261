<script setup lang="ts">
import type { AvatarSize } from '@/types/avatar';
import { RouterLink } from 'vue-router';

const props = defineProps({
	imageUrl: {
		type: String as PropType<string | null>,
		default: null,
		required: true,
	},
	name: {
		type: String,
		default: '',
	},
	userId: {
		type: Number,
		default: null,
		required: true,
	},
	alt: {
		type: String,
		default: null,
	},
	size: {
		type: String as PropType<AvatarSize>,
		default: AvatarSizes[2],
	},
	isPreviewMode: {
		type: Boolean,
		default: false,
	},
});

const profileStore = useProfileStore();

const routerLink = computed(() => {
	if (props.isPreviewMode) {
		return {};
	}

	if (props.userId === profileStore.profile?.id) {
		return {
			name: ROUTE.PROFILE.NAME,
		};
	}

	return {
		name: ROUTE.USER.NAME,
		params: {
			id: props.userId,
		},
	};
});
</script>

<template>
  <component
    :is="isPreviewMode ? 'div' : RouterLink"
    class="avatar-link text-sm text-semibold cursor-pointer"
    :to="routerLink"
  >
    <AvatarImage
      class="avatar-image"
      :image-url="imageUrl"
      :alt="alt"
      :size="size"
    />
    <slot />
  </component>
</template>
<style lang="scss" scoped>
.avatar-link {
	width: fit-content;
	display: flex;
	align-items: center;
	gap: spacings-get(2);
	color: colors-get(primary, 700);
	text-decoration: none;

	@include media-query-max(mobile) {
		padding-left: 0;
	}

	.avatar-image {
		aspect-ratio: 1;
	}
}
</style>
