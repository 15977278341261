<script setup lang="ts">
const attrs = useAttrs();
const { isSafari } = useBrowserDetect();

const classes = computed(() => {
	if (isSafari.value && attrs.indeterminate !== undefined) {
		return 'is-safari';
	}
	return '';
});
</script>
<template>
  <VProgressCircular
    :class="[classes]"
    v-bind="attrs"
  />
</template>