<script setup lang="ts">
import type { ButtonList } from '@/types/common';

const buttonList: ButtonList[] = [{
	name: 'Terms',
	url: ROUTE.TERMS.NAME,
},
{
	name: 'Pricing',
	url: ROUTE.PRICING.NAME,
},
{
	name: 'Help',
	url: ROUTE.HELP.NAME,
},
{
	name: 'About us',
	url: ROUTE.ABOUT_US.NAME,
},
];

</script>
<template>
  <div class="footer-link">
    <RouterLink
      v-for="(btn, index) in buttonList"
      :key="index"
      :to="{name: btn.url }"
    >
      <BaseButton
        class="link"
        variant="link"
        color="gray"
      >
        {{ btn.name }}
      </BaseButton>
    </RouterLink>
  </div>
</template>
<style lang="scss" scoped>
.footer-link {
    display: flex;
    gap: spacings-get(8);
    @include fonts-get(semibold, text-md);
    color: colors-get(gray, 600);

    .link {
      padding: 0;
    }
}

@include media-query-max(tablet) {
    .footer-link {
        flex-direction: column;
        align-items: center;
        gap: rem(18);
    }
}
</style>


