import type { RouteRecordNameGeneric } from 'vue-router';

export function useSetDocumentTitle() {
	const setDocumentTitle = (routeName: RouteRecordNameGeneric, replacedText?: string, isTrackGAEvent: boolean = true) => {
		const { trackEvent } = useGtm();
		// Get the page title from the route's 'name' attribute (if it exists)
		const title = getRouteTitleByName(routeName);
		// Check if page title has replaceable token
		const isDynamicPageTitle = REGEX_MATCH_CURLY_BRACES_PATTERN.test(title);
		// Not update title if page has replaceable token but no replace text
		if (isDynamicPageTitle && !replacedText) {
			return;
		}
		// Replace token in the title with new text if it has
		const newTitle = replacedText ? replaceTextInCurlyBraces(title, [replacedText]) : title;

		// Set document title
		document.title = newTitle;

		// Skip GA tracking
		if (!isTrackGAEvent) {
			return;
		}

		// Track event
		trackEvent({
			page_title: newTitle,
			event: GTM_EVENT_NAME.VIRTUAL_PAGE_VIEW,
		});
	};

	return {
		setDocumentTitle,
	};
}
