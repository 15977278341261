<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

interface Message {
	subject: string;
	body: string;
}

const props = defineProps({
	receiver: {
		type: String,
		default: '',
	},
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onSend', subject: string, body: string): void
}>();

const formData = reactive<Message>({
	subject: '',
	body: '',
});

const isShowValue = computed(() => props.isShow);

const toReceiverText = computed(() => {
	return props.receiver || 'All attendees';
});

const rules = computed(() => {
	if (!props.isShow) {
		return {
			body: {},
		};
	}

	return {
		body: {
			required: helpers.withMessage('Please enter your message.', required),
		},
	};
});

const v$ = useVuelidate(rules, formData);

function handleSubmit() {
	v$.value.$touch();

	if (v$.value.$error) {
		return;
	}

	emit('onSend', formData.subject, formData.body);
}

watch(() => props.isShow, (newValue) => {
	if (newValue) {
		formData.subject = '';
		formData.body = '';
	}
	v$.value.$reset();
});
</script>

<template>
  <BaseDialog
    :is-show="isShowValue"
    @on-close="emit('onClose')"
  >
    <template #header>
      <p class="text-lg text-semibold">
        Send message
      </p>
    </template>
    <template #content>
      <div class="content-wrapper">
        <div class="receiver-container text-md">
          <p class="receiver-header">
            To
          </p>
          <p class="receiver-name">
            {{ toReceiverText }}
          </p>
        </div>
        <div class="message-container">
          <BaseTextInput
            v-model="formData.subject"
            class="subject-input"
            placeholder="Subject (optional)"
            :disabled="disabled"
          />
          <BaseTextArea
            v-model="formData.body"
            placeholder="Type your message"
            rows="8"
            font-size="md"
            :disabled="disabled"
            :error-message="getValidationErrorMessage(v$.body.$errors)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Send
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(4);

  .receiver-container {
    display: flex;

    .receiver-header {
      width: rem(60);
      color: colors-get(gray, 600);
    }

    .receiver-name {
      color: colors-get(gray, 900);
    }
  }

  .message-container {
    display: flex;
    flex-direction: column;
    gap: spacings-get(3);

    .subject-input {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>