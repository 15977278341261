<script setup lang="ts">
defineProps({
	width: {
		type: String,
		default: '',
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const slots = defineSlots();
const hasSlotContent = computed(() => !!slots.default);
</script>

<template>
  <template v-if="hasSlotContent">
    <VSkeletonLoader

      type="paragraph"
      :loading="loading"
      :width="width"
    >
      <slot />
    </VSkeletonLoader>
  </template>
  <template v-else>
    <VSkeletonLoader
      type="paragraph"
      :loading="loading"
      :width="width"
    />
  </template>
</template>

<style scoped lang="scss">
:deep(.v-skeleton-loader__paragraph) {
    gap: spacings-get(3);

    > .v-skeleton-loader__text {
        margin: 0;
    }
}
</style>
