<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M22.5 21V19C22.5 17.1362 21.2252 15.5701 19.5 15.126M16 3.29076C17.4659 3.88415 18.5 5.32131 18.5 7C18.5 8.67869 17.4659 10.1159 16 10.7092M17.5 21C17.5 19.1362 17.5 18.2044 17.1955 17.4693C16.7895 16.4892 16.0108 15.7105 15.0307 15.3045C14.2956 15 13.3638 15 11.5 15H8.5C6.63623 15 5.70435 15 4.96927 15.3045C3.98915 15.7105 3.21046 16.4892 2.80448 17.4693C2.5 18.2044 2.5 19.1362 2.5 21M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
