<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
	strokeWidth: {
		type: String,
		default: '2',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      :stroke="iconColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
