<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '26',
	},
	height: {
		type: String,
		default: '18',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Icon"
      d="M25 6.71429H1M11.8 11.2857H5.8M1 4.65714L1 13.3429C1 14.623 1 15.263 1.26158 15.752C1.49168 16.1821 1.85883 16.5317 2.31042 16.7509C2.82381 17 3.49587 17 4.84 17L21.16 17C22.5041 17 23.1762 17 23.6896 16.7509C24.1412 16.5317 24.5083 16.1821 24.7384 15.752C25 15.263 25 14.623 25 13.3429V4.65714C25 3.37702 25 2.73696 24.7384 2.24802C24.5083 1.81794 24.1412 1.46827 23.6896 1.24913C23.1762 1 22.5041 1 21.16 1L4.84 1C3.49587 1 2.82381 1 2.31042 1.24913C1.85883 1.46827 1.49168 1.81794 1.26158 2.24802C1 2.73696 1 3.37702 1 4.65714Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
