
<script setup lang="ts">
import Highlighter from 'vue-highlight-words';
import scrollIntoView from 'scroll-into-view-if-needed';
import type { Timezone } from '@/types/country-timezone';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';

const props = defineProps({
	selectedTimezone: {
		type: String,
		default: 'America/New_York',
		required: true,
	},
	timezoneOptions: {
		type: Array as PropType<Array<Timezone>>,
		default: () => [],
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onSelectTimezone', item: Timezone): void;
	(e: 'onMenuChange', isOpen: boolean): void;
}>();
const searchInput = ref('');
const isOpen = ref(false);

const timezones = computed(() => props.timezoneOptions);

const dropdownActivatorId = computed(() => `dropdown-activator-${new Date().getTime()}`);

const filterTimezones = computed(() => {
	if (!searchInput.value) {
		return timezones.value;
	}

	const searchValue = searchInput.value.toLowerCase();
	return timezones.value.filter((timezone) => {
		const tzName = timezone.name.toLowerCase();
		return tzName.includes(searchValue);
	});
});

const selectedTimezoneOption = computed(() => {
	return timezones.value.find((timezoneItem) => timezoneItem.timezone === props.selectedTimezone);
});

const displaySelectedTimezoneText = computed(() => {
	return selectedTimezoneOption.value ? convertDisplaySelectedTimezone(selectedTimezoneOption.value) : '';
});

const chevronIconComponent = computed(() => (isOpen.value ? ChevronUpIcon : ChevronDownIcon));

function handleItemClick(item: Timezone) {
	emit('onSelectTimezone', item);
	isOpen.value = false;
}

watch(isOpen, (newIsOpen) => {
	if (!newIsOpen) {
		searchInput.value = '';
	} else {
		// delay to make sure element is rendered.
		setTimeout(() => {
			// Scroll to selected element
			const selectedElement = document.querySelector('.v-overlay .list-box .selected');
			if (selectedElement) {
				// Recommend to use https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
				// When it fully support
				scrollIntoView(selectedElement, {
					scrollMode: 'if-needed',
				});
			}
		}, 200);
	}

	emit('onMenuChange', newIsOpen);
});

</script>
<template>
  <div
    :id="dropdownActivatorId"
    class="timezone-selection-container"
    v-bind="$attrs"
  >
    <slot name="dropdownActivator" />
    <div
      v-if="!$slots['dropdownActivator']"
      class=""
      :class="[
        'wrapper text-xs text-regular',
        {
          '--disabled': disabled,
        }
      ]"
    >
      <span class="title">Timezone</span>
      <span
        class="value"
      >{{ displaySelectedTimezoneText }}</span>
      <component
        :is="chevronIconComponent"
        class="icon"
        width="16"
        height="16"
        :color="disabled? ICON_COLOR.DISABLED : ICON_COLOR.PRIMARY"
      />
    </div>
  </div>
  <VMenu
    v-model="isOpen"
    :activator="`#${dropdownActivatorId}`"
    :close-on-content-click="false"
    :disabled="disabled"
    location="bottom"
    offset="6"
    width="200"
  >
    <div class="list-box">
      <BaseTextInput
        v-model="searchInput"
        class="search-box"
        placeholder="Search by city"
      >
        <template #leftIcon>
          <SearchIcon
            class="icon"
            color="#667085"
          />
        </template>
      </BaseTextInput>
      <VList class="search-items">
        <VListItem v-if="!filterTimezones.length">
          <span class="no-data text-md text-regular">No results found</span>
        </VListItem>
        <VListItem
          v-for="(timezone, index) in filterTimezones"
          v-else
          :key="index"
          min-height="44"
          class="item text-regular"
          @click="handleItemClick(timezone)"
        >
          <div :class="['item-content', {'selected': selectedTimezone === timezone.timezone}]">
            <div>
              <Highlighter
                class="item-label text-md"
                highlight-class-name="text-medium"
                :search-words="[searchInput]"
                :auto-escape="true"
                :text-to-highlight="timezone.name"
              />
              <span class="item-timezone-offset text-sm">{{ `(GMT${timezone.timezoneOffset})` }}</span>
            </div>
            <CheckIcon
              v-if="selectedTimezone === timezone.timezone"
              :color="ICON_COLOR.PRIMARY"
            />
          </div>
        </VListItem>
      </VList>
    </div>
  </VMenu>
</template>
<style scoped lang="scss">
.timezone-selection-container {
  .wrapper {
    display: flex;
    align-items: center;
    gap: spacings-get(2);

    .title {
      color: colors-get(gray, 700);
    }

    .value {
      color: colors-get(base, black);
    }

    &.--disabled .value {
      color: colors-get(gray, 300);
    }
  }
}
.list-box {
    border: 1px solid colors-get(gray, 200);
    background-color: colors-get(base, white);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    @include border-radius-default;

    .search-box {
        margin: spacings-get(2) rem(6);

        :deep(.text-field) {
            padding-left: spacings-get(2);
        }

        .icon {
            padding-left: rem(14);
            box-sizing: content-box;
        }
    }

    .search-items {
        max-height: rem(246);
        padding: 0 0 rem(10);
        border-bottom-left-radius: border-radius-get(default);
        border-bottom-right-radius: border-radius-get(default);
        box-sizing: border-box;

        .no-data {
          color: colors-get(gray, 400);
        }

        .item {
            margin: rem(2) rem(6);
            padding: rem(10) rem(10) rem(10) spacings-get(2);
            border-radius: 6px;
            box-sizing: border-box;

            .item-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: spacings-get(2);
            }

            .item-label {
              margin-right: spacings-get(2);
              color: colors-get(gray, 900);
            }

            .item-timezone-offset {
              color: colors-get(gray, 600);
            }

            &:hover {
                background-color: colors-get(gray, 50);
            }
        }
    }
}
</style>