<script setup lang="ts">
import type { ButtonGroupItem } from '@/types/button-group';

const props = defineProps({
	modelValue: {
		type: String,
		default: '',
	},
	items: {
		type: Array as PropType<Array<ButtonGroupItem>>,
		default: () => [],
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void; }>();

const activeButton = computed(() => props.modelValue);

function handleClick(e: MouseEvent) {
	const target = e.target as HTMLInputElement;
	const value = target.value;
	emit('update:modelValue', value);
}
</script>
<template>
  <div
    class="btn-group"
  >
    <button
      v-for="(item, index) in items"
      :key="index"
      :value="item.value"
      :class="['btn-item', { 'active': activeButton === item.value }]"
      :disabled="disabled"
      @click="handleClick"
    >
      {{ item.name }}
    </button>
  </div>
</template>
<style lang="scss" scoped>
.btn-group {
    display: flex;
    gap: rem(10);
    padding: spacings-get(2);
    width: fit-content;
    background: colors-get(gray, 100);
    @include border-radius-default;
}

.btn-item {
    padding: spacings-get(2) rem(14);
    @include fonts-get(semibold, text-sm);
    color: colors-get(gray, 700);
    @include border-radius-default;

    &.active {
        background: colors-get(base, white);
    }

    &:disabled {
        color: colors-get(gray, 300);
    }
}
</style>