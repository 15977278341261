import type { Appearance } from '@stripe/stripe-js';

const appearance: Appearance = {
	theme: 'flat',
	variables: {
		colorBackground: '#ffffff',
		borderRadius: '12px',
		fontSizeBase: '1rem',
		// fontFamily: 'Inter, Arial, sans-serif', // TODO: Load font
	},
	rules: {
		'.Input': {
			boxShadow: 'none',
			border: '1px solid #D0D5DD',
		},
		'.Input:focus': {
			boxShadow: '0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
			border: '1px solid #A4BCFE',
		},
		'.Input--invalid': {
			boxShadow: 'none',
			border: '1px solid #F04438',
		},
		'.Input--invalid:focus': {
			border: '1px solid #FDA29B',
			boxShadow: '0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
		},
		'.Label': {
			fontSize: '0.875rem',
			color: '#344054',
			fontWeight: '500',
			marginBottom: '6px',
		},
		'.Error': {
			fontSize: '0.875rem',
			color: '#F04438',
			marginTop: '6px',
		},
		'.TermsText': {
			fontSize: '0px', // Hide terms message
		},
	},
};

export default appearance;