export function useBrowserDetect() {
	const isSafari = ref(false);

	onMounted(() => {
		isSafari.value = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	});

	return {
		isSafari,
	};
}
