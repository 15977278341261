
<script setup lang="ts">
const profileStore = useProfileStore();
const notificationStore = useNotificationStore();
const isTicketSalesChecked = ref(false);

const userId = computed(() => profileStore.profile?.id);

const isLoading = computed(() => profileStore.isLoading);

async function fetchNotification() {
	if (!userId.value) {
		return;
	}

	await profileStore.getNotificationSetting(userId.value);
	isTicketSalesChecked.value = profileStore.notificationSetting.sales;
}

async function handleSave() {
	if (!userId.value) {
		return;
	}

	await profileStore.updateNotificationSetting(userId.value, {
		sales: isTicketSalesChecked.value,
		offer: profileStore.notificationSetting.offer,
		recommendation: profileStore.notificationSetting.recommendation,
	});

	if (profileStore.notificationSettingResponse.errorMessage) {
		notificationStore.showErrorNotification(profileStore.notificationSettingResponse.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully updated your notification settings.');
	}
}

onMounted(() => {
	fetchNotification();
});

watch(userId, () => {
	fetchNotification();
});
</script>
<template>
  <div class="form-setting">
    <div class="row noti-setting-container">
      <label class="text-sm text-semibold">Organizer emails</label>
      <ParagraphSkeletonLoader v-if="isLoading" />
      <template v-else>
        <BaseCheckbox
          id="ticket-sales-summary"
          v-model="isTicketSalesChecked"
          label="Ticket sales summary"
          :disabled="isLoading"
        >
          <template #helperText>
            Be notified with a daily sales summary of your group’s event ticket sales when new tickets are sold.
          </template>
        </BaseCheckbox>
      </template>
    </div>
    <div class="footer">
      <ButtonSkeletonLoader
        :loading="isLoading"
        width="65"
        height="42"
      >
        <BaseButton
          :size="BtnSize[1]"
          :disabled="isLoading"
          @click="handleSave"
        >
          Save
        </BaseButton>
      </ButtonSkeletonLoader>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-setting {
  :deep(.v-skeleton-loader__bone) {
    gap: spacings-get(1);
  }
}
</style>