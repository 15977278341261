<script setup lang="ts">
import AlertCircleIcon from '@/components/icons/AlertCircleIcon.vue';
import InfoCircleIcon from '@/components/icons/InfoCircleIcon.vue';

const props = defineProps({
	type: {
		type: String,
		default: 'warning',
	},
	hideIcon: {
		type: Boolean,
		default: false,
	},
});

const cssClass = computed(() => {
	return `--${props.type}`;
});

const iconColor = computed(() => {
	switch (props.type) {
		case ALERT_TYPE.INFO:
			return '#475467';
		case ALERT_TYPE.PRIMARY:
			return '#444CE7';
		default:
			return '#DC6803';
	}
});

const iconComponent = computed(() => {
	switch (props.type) {
		case ALERT_TYPE.INFO:
			return InfoCircleIcon;
		default:
			return AlertCircleIcon;
	}
});
</script>
<template>
  <div
    :class="['alert', cssClass]"
  >
    <slot name="icon" />
    <component
      :is="iconComponent"
      v-if="!$slots['icon'] && !hideIcon"
      :color="iconColor"
      width="20"
      height="20"
    />
    <slot />
  </div>
</template>
<style scoped lang="scss">
.alert {
  display: grid;
  grid-template-columns: rem(20) 1fr;
  gap: spacings-get(3);
  padding: spacings-get(3);
  @include border-radius-lg;
  color: colors-get(warning, 600);
  background-color: colors-get(warning, 25);

  &.--info {
    color: colors-get(gray, 600);
    background-color: colors-get(gray, 50);
  }

  &.--warning {
    color: colors-get(warning, 600);
    background-color: colors-get(warning, 25);
  }

  &.--confirm {
    color: colors-get(gray, 950);
    background-color: colors-get(gray, 50);
  }

  &.--primary {
    color: colors-get(primary, 700);
    background-color: colors-get(primary, 25);
  }
}
</style>