<script setup lang="ts">
defineProps({
	loading: {
		type: Boolean,
		default: false,
	},
	rows: {
		type: String,
		default: '5',
	},
	buttonWidth: {
		type: String,
		default: '136',
	},
});

const { isMobile } = useWindowResize();

</script>
<template>
  <MultipleSkeletonLoader
    class="loader-event-container"
    :rows="rows"
    has-divider
  >
    <div class="loader-event-item">
      <ImageSkeletonLoader :height="isMobile ? `172` : `80`" />
      <ParagraphSkeletonLoader :width="isMobile ? `` : `80%`" />
      <ButtonSkeletonLoader
        class="action"
        :width="isMobile ? `100%` : buttonWidth"
      />
    </div>
  </MultipleSkeletonLoader>
</template>
<style scoped lang="scss">

.loader-event-container {
      @include media-query-max(mobile) {
        padding: spacings-get(4) 0;
      }

    .loader-event-item {
      display: grid;
      grid-template-columns: rem(160) auto rem(136);
      gap: spacings-get(4);
      align-items: center;
      padding: spacings-get(6) 0;

      @include media-query-max(mobile) {
        grid-template-columns: 1fr;
        padding: 0 0 spacings-get(4);
      }

      .action {
        margin: auto;
      }
    }
  }
</style>