<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M6.5 11V15M18.5 9V13M17.5 4C19.9487 4 21.2731 4.37476 21.9321 4.66544C22.0199 4.70415 22.0638 4.72351 22.1904 4.84437C22.2663 4.91682 22.4049 5.12939 22.4405 5.22809C22.5 5.39274 22.5 5.48274 22.5 5.66274V16.4111C22.5 17.3199 22.5 17.7743 22.3637 18.0079C22.2251 18.2454 22.0914 18.3559 21.8319 18.4472C21.5769 18.5369 21.062 18.438 20.0322 18.2401C19.3114 18.1017 18.4565 18 17.5 18C14.5 18 11.5 20 7.5 20C5.05129 20 3.72687 19.6252 3.06788 19.3346C2.98012 19.2958 2.93624 19.2765 2.8096 19.1556C2.73369 19.0832 2.59512 18.8706 2.55947 18.7719C2.5 18.6073 2.5 18.5173 2.5 18.3373L2.5 7.58885C2.5 6.68009 2.5 6.2257 2.63628 5.99214C2.7749 5.75456 2.90859 5.64412 3.16806 5.55281C3.42314 5.46305 3.93803 5.56198 4.96783 5.75985C5.68862 5.89834 6.54348 6 7.5 6C10.5 6 13.5 4 17.5 4ZM15 12C15 13.3807 13.8807 14.5 12.5 14.5C11.1193 14.5 10 13.3807 10 12C10 10.6193 11.1193 9.5 12.5 9.5C13.8807 9.5 15 10.6193 15 12Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
