import type { PaymentCard } from '@/types/common';
import type {
	UserProfile,
	ProfileResponse,
	SocialProfile,
	Social,
	GroupInfo,
	GroupInfoResponse,
	CardListResponse,
	FollowingGroup,
	TransformedFollowingGroup,
	TicketPurchasesHistoryResponse,
	TicketPurchaseHistoryItemInfo,
	TicketPurchaseHistoryItemResponse,
	TicketOrderHistoryItem,
	TicketOrderHistoryItemResponse,
} from '@/types/profile';

const createSocialPrefixLinkMap = () => {
	const socialPrefixLinkMap = new Map();
	socialPrefixLinkMap.set(SOCIAL_NAME.FACEBOOK, SOCIAL_PREFIX_LINK.FACEBOOK);
	socialPrefixLinkMap.set(SOCIAL_NAME.X, SOCIAL_PREFIX_LINK.X);
	socialPrefixLinkMap.set(SOCIAL_NAME.INSTAGRAM, SOCIAL_PREFIX_LINK.INSTAGRAM);
	socialPrefixLinkMap.set(SOCIAL_NAME.TIKTOK, SOCIAL_PREFIX_LINK.TIKTOK);
	socialPrefixLinkMap.set(SOCIAL_NAME.YOUTUBE, SOCIAL_PREFIX_LINK.YOUTUBE);
	socialPrefixLinkMap.set(SOCIAL_NAME.LINKEDIN, SOCIAL_PREFIX_LINK.LINKEDIN);
	socialPrefixLinkMap.set(SOCIAL_NAME.WEBSITE, SOCIAL_PREFIX_LINK.WEBSITE);
	return socialPrefixLinkMap;
};

export const transformProfile = (data: ProfileResponse): UserProfile => {
	return {
		...data,
		name: data.nickname,
		avatarUrl: data.avatar || '',
		avatarLargeUrl: data.avatar_large || '',
		countryId: data.country_of_residence.id,
		timezone: data.timezone_id,
		isVerified: !!data.verification_step,
		emergencyContact: data.emergency_contact,
	};
};

export const transformSocialInfo = (data: SocialProfile[]): Social | null => {
	if (!data.length) {
		return null;
	}

	return data?.reduce(
		(social, { sns, profileUrl }) => {
			social[sns] = profileUrl;
			return social;
		},
		{} as { [key: string]: string },
	);
};

export const transformSocialLink = (data: SocialProfile[]): Social[] => {
	if (!data.length) {
		return [];
	}

	const socialPrefixLinkMap = createSocialPrefixLinkMap();
	const sortedKeys = Array.from(socialPrefixLinkMap.keys());

	const modifiedSocialLinks = data.map((item) => {
		const { sns, profileUrl } = item;
		const defaultUrl = socialPrefixLinkMap.get(sns) || '';

		if (sns === SOCIAL_NAME.WEBSITE) {
			return { sns, profileUrl };
		} else if (profileUrl) {
			return { sns, profileUrl: defaultUrl + profileUrl };
		}
		return { sns, profileUrl: '' };
	});

	const sortedSocialLinks = modifiedSocialLinks.sort((a, b) => {
		return sortedKeys.indexOf(a.sns) - sortedKeys.indexOf(b.sns);
	});

	const filteredSocialLinks = sortedSocialLinks.filter((link) => link.profileUrl);
	return filteredSocialLinks;
};

export const transformProfileGroup = (data: GroupInfo, updatedData: GroupInfoResponse | null): GroupInfo => {
	if (!updatedData) {
		return data;
	}

	return {
		...data,
		...updatedData,
	};
};

export const transformFollowingList = (data: FollowingGroup[]): TransformedFollowingGroup[] => {
	if (!data.length) {
		return [];
	}

	return data.map((item) => {
		return {
			...item,
			isFollow: true,
			followedAt: convertDateTimeToUtc(item.followedAt.utc, item.followedAt.timezone, SETTING_DISPLAY_DATE_FORMAT),

		};
	});
};

export const transformCardList = (data: CardListResponse | null): PaymentCard[] => {
	if (!data) {
		return [];
	}

	return data.data.map((card) => {
		return {
			...card,
			isExpired: isCardExpired(card.expires),
		};
	});
};

const transformTicketOrderHistory = (data: TicketOrderHistoryItemResponse): TicketOrderHistoryItem => ({
	...data,
	updated: convertDateTimeToUtc(data.updated.utc, data.updated.timezone, SETTING_DISPLAY_DATE_FORMAT),
	created: convertDateTimeToUtc(data.created.utc, data.created.timezone, SETTING_DISPLAY_DATE_FORMAT),
});

export const transformTicketPurchaseHistory = (data: TicketPurchaseHistoryItemResponse): TicketPurchaseHistoryItemInfo => {
	const {orderDate, eventName, orderHistories, ...rest} = data;

	const filteredOrderHistories = orderHistories.filter((item) => item.status === TICKET_ORDER_HISTORY_STATUS.CANCELED);
	const modifiedOrderHistories = filteredOrderHistories.map(transformTicketOrderHistory);

	let newStatus = data.status;
	// Adjust status for `Paid` status order that only have free ticket left
	if (data.status === TICKET_PURCHASE_HISTORY_STATUS.PAID && data.amount === 0) {
		const sumOfCanceledTickets = data.orderHistories.reduce((sum: number, item: TicketOrderHistoryItemResponse) => {
			if (item.status === TICKET_ORDER_HISTORY_STATUS.CANCELED) {
				return item.totalAmount + sum;
			}
			return sum;
		}, 0);

		if (sumOfCanceledTickets === data.fullAmount) {
			newStatus = TICKET_PURCHASE_HISTORY_STATUS.REFUND;
		}
	}
	return {
		...rest,
		orderDate: convertDateTimeToUtc(orderDate.utc, orderDate.timezone, SETTING_DISPLAY_DATE_FORMAT),
		name: eventName,
		orderHistories: modifiedOrderHistories,
		status: newStatus,
	};
};

export const transformTicketPurchaseHistoryList = (data: TicketPurchasesHistoryResponse | null): TicketPurchaseHistoryItemInfo[] => {
	return data?.data.map(transformTicketPurchaseHistory) || [];
};