<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M17.5725 0H13.5278V16.3478C13.5278 18.2957 11.9722 19.8957 10.0363 19.8957C8.10034 19.8957 6.54469 18.2957 6.54469 16.3478C6.54469 14.4348 8.06577 12.8695 9.93257 12.8V8.69567C5.81872 8.7652 2.5 12.1391 2.5 16.3478C2.5 20.5913 5.88786 24 10.0708 24C14.2538 24 17.6416 20.5565 17.6416 16.3478V7.9652C19.1627 9.07827 21.0295 9.73913 23 9.77393V5.66957C19.9579 5.56522 17.5725 3.06087 17.5725 0Z"
      :fill="iconColor"
    />
  </svg>
</template>