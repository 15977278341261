<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '18',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 18"
    fill="none"
  >
    <path
      d="M24.2609 4.20005C24.2609 4.20005 24.0266 2.54536 23.3047 1.8188C22.3906 0.862549 21.3688 0.857861 20.9 0.801611C17.5438 0.557861 12.5047 0.557861 12.5047 0.557861H12.4953C12.4953 0.557861 7.45625 0.557861 4.1 0.801611C3.63125 0.857861 2.60938 0.862549 1.69531 1.8188C0.973438 2.54536 0.74375 4.20005 0.74375 4.20005C0.74375 4.20005 0.5 6.14536 0.5 8.08599V9.90474C0.5 11.8454 0.739062 13.7907 0.739062 13.7907C0.739062 13.7907 0.973437 15.4454 1.69062 16.1719C2.60469 17.1282 3.80469 17.0954 4.33906 17.1985C6.26094 17.3813 12.5 17.4375 12.5 17.4375C12.5 17.4375 17.5438 17.4282 20.9 17.1891C21.3688 17.1329 22.3906 17.1282 23.3047 16.1719C24.0266 15.4454 24.2609 13.7907 24.2609 13.7907C24.2609 13.7907 24.5 11.85 24.5 9.90474V8.08599C24.5 6.14536 24.2609 4.20005 24.2609 4.20005ZM10.0203 12.1125V5.36724L16.5031 8.75161L10.0203 12.1125Z"
      :fill="iconColor"
    />
  </svg>
</template>