import type {
	StripeAccountResponse,
	StripeLinkResponse,
	OnboardingData,
} from '@/types/connect';

interface ConnectStripeStore {
	connect: {
		data: StripeAccountResponse | null
		onboardingData: StripeLinkResponse | null
		errorMessage: string
	}
	isLoading: boolean
}

const useStripeConnectStore = defineStore('stripe-connect', {

	state: (): ConnectStripeStore => ({
		// Contain Stripe connect flow information
		connect: {
			data: null,
			onboardingData: null,
			errorMessage: '',
		},
		isLoading: false,
	}),

	getters: {
		isStripeAccountEnabled: (state: ConnectStripeStore): boolean => {
			if (!state.connect.data?.data.length) {
				return false;
			}
			// There can be only one stripe id for an account connection
			const connectionData = state.connect.data.data[0];
			return connectionData.chargesEnabled && connectionData.payoutsEnabled && connectionData.detailsSubmitted;
		},

		stripeAccountId: (state: ConnectStripeStore) => {
			if (!state.connect.data?.data.length) {
				return null;
			}
			return state.connect.data?.data[0].id;
		},

		stripeOnboardingLink: (state: ConnectStripeStore) => {
			if (!state.connect.onboardingData) {
				return null;
			}

			return state.connect.onboardingData.url;
		},
	},

	actions: {
		async initiateStripeOnboardingProcess(userId: number, onboardingData: OnboardingData) {
			this.isLoading = true;
			this.connect.errorMessage = '';

			const { data: result, errorMessage } = await postOnboardingStripeAccount(userId, onboardingData);

			if (errorMessage.value) {
				this.connect.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.connect.onboardingData = result.value;
			}
			this.isLoading = false;
		},

		async disconnectStripeAccount(userId: number) {
			this.isLoading = true;
			this.connect.errorMessage = '';

			const { errorMessage } = await deleteDisconnectStripeAccount(userId);

			if (errorMessage.value) {
				this.connect.errorMessage = errorMessage.value;
			}

			this.isLoading = false;
		},

		async getStripeAccountInfo(userId: number) {
			this.isLoading = true;
			this.connect.errorMessage = '';

			const { data: result, errorMessage } = await getStripeAccountInfoByUserId(userId);

			if (errorMessage.value) {
				this.connect.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.connect.data = result.value;
			}
			this.isLoading = false;
		},
	},
});

export default useStripeConnectStore;
