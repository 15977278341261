<template>
  <section class="get-started-section">
    <div class="section-inner">
      <h1 class="text-semibold">
        Get started today
      </h1>
      <RouterLink :to="{name: ROUTE.EVENT_CREATE.NAME}">
        <BaseButton :size="BtnSize[3]">
          Create event
        </BaseButton>
      </RouterLink>
    </div>
  </section>
</template>
<style scope lang="scss">
.get-started-section {
  padding: spacings-get(12) 0;
  color: colors-get(base, white);
  background-color: colors-get(gray, 600);

  h1 {
    @include fonts-size(display-md);

    @include media-query-max(mobile) {
      @include fonts-size(display-sm);
    }
  }

  .section-inner {
    display: grid;
    gap: spacings-get(6);
  }
}
</style>