<script setup lang="ts">
import type { ButtonGroupItem } from '@/types/button-group';
import type { UserEventInfo } from '@/types/profile';

const props = defineProps({
	userId: {
		type: Number as PropType<number | null>,
		required: true,
	},
	groupId: {
		type: Number as PropType<number | null>,
		required: true,
	},
	isProfileLoading: {
		type: Boolean,
		default: false,
	},
});

const userStore = useUserStore();
const { isMobile } = useWindowResize();

const filterItem: ButtonGroupItem[] = [
	{
		name: 'Active',
		value: USER_EVENT_HOSTING_FILTER.ACTIVE,
	},
	{
		name: 'Archived',
		value: USER_EVENT_HOSTING_FILTER.ARCHIVED,
	},
];

const selectedFilter = ref<string>(filterItem[0].value);
const currentPage = ref(1);
const items = ref<UserEventInfo[]>([]);

const profileId = computed(() => props.userId);
const groupId = computed(() => props.groupId);
const totalPages = computed(() => userStore.userEventHosting.singleResponse?.paginationInfo.totalPages);
const isLoading = computed(() => userStore.userEventHosting.isLoading || props.isProfileLoading);

function fetchData() {
	if (profileId.value && groupId.value) {
		userStore.getUserHostingEventById(
			profileId.value,
			{
				itemPerPage: PAGINATION_CONFIG.ITEM_PER_PAGE,
				currentPage: currentPage.value,
				filter: selectedFilter.value,
				groupId: groupId.value,
				sortBy: USER_EVENT_SORTING.SORT_DESC,
			},
			true,
		);
	}
}

function handleFilterChange() {
	currentPage.value = 1;
	items.value = [];
	fetchData();
}

function handlePageChange(page: number) {
	currentPage.value = page;
	fetchData();
}

onMounted(() => {
	fetchData();
});

watch([profileId, groupId], () => {
	fetchData();
});

watch(() => userStore.userEventHostingList, (newUserEventHostingList) => {
	items.value = newUserEventHostingList;
});
</script>
<template>
  <div class="hosting-list">
    <div class="header">
      <h1 class="text-lg text-semibold">
        Events
      </h1>
      <BaseToggleButtonGroup
        v-model="selectedFilter"
        :items="filterItem"
        @update:model-value="handleFilterChange"
      />
    </div>
    <template v-if="items?.length">
      <div class="items">
        <EventDetailsItem
          v-for="item in items"
          :key="item.id"
          :data="item"
          is-hosting-user-profile
        >
          <EventStatusTag
            class="status"
            :status="item.status"
            :size="isMobile ? TagSizes[0] : TagSizes[3]"
          />
        </EventDetailsItem>
      </div>
      <div class="paginate">
        <BasePagination
          :page="currentPage"
          :total-pages="totalPages"
          @on-page-change="handlePageChange"
        />
      </div>
    </template>
    <template v-else>
      <EventListLoader
        v-if="isLoading"
        button-width="105"
      />
      <NoDataFound v-else>
        <template #content>
          <h1 class="text-md text-semibold">
            No events found
          </h1>
          <p class="text-sm text-regular">
            Your events will be listed here once you create an event.
          </p>
        </template>
        <template #action>
          <RouterLink
            class="w-full"
            :to="{
              name: ROUTE.EVENT_CREATE.NAME,
            }"
          >
            <BaseButton>Create event</BaseButton>
          </RouterLink>
        </template>
      </NoDataFound>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.hosting-list {
  margin-top: spacings-get(4);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status {
    width: fit-content;
  }
}
</style>