<script setup lang="ts">

const props = defineProps({
	id: {
		type: String,
		default: '',
	},
	label: {
		type: String,
		default: undefined,
	},
	hint: {
		type: String,
		default: undefined,
	},
	rows: {
		type: String,
		default: '15',
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: undefined,
	},
	modelValue: {
		type: String as PropType<string>,
		default: '',
	},
	errorMessage: {
		type: String,
		default: undefined,
	},
	isShowCustomError: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	resizable: {
		type: Boolean,
		default: false,
	},
	labelClass: {
		type: String,
		default: 'text-md text-regular',
	},
	fontSize: {
		type: String,
		default: '',
	},
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | number | null): void;
	(e: 'focus'): void;
	(e: 'blur'): void;
	(e: 'change'): void;
}>();

const { width } = useWindowResize();
const inputElement = ref<HTMLInputElement | null>(null);
defineExpose({ input: inputElement });
const isInputFocus = ref(false);

function handleClickLabel() {
	inputElement.value?.focus();
}

function handleFocus() {
	isInputFocus.value = true;
	emit('focus');
}

function handleBlur() {
	isInputFocus.value = false;
	emit('focus');
}

function handleInput(event: Event) {
	const targetElement = event.target as HTMLInputElement;
	emit('update:modelValue', targetElement.value);
}

// Set font size = 16px on mobile to prevent auto zoom
const fontSizeClass = computed(() => {
	if (props.fontSize) {
		return `text-${props.fontSize}`;
	}
	return width.value >= SCREEN_SIZE.TABLET ? 'text-sm' : 'text-md';
});

const isError = computed(() => {
	return !!props.errorMessage;
});
</script>

<template>
  <div class="input-container">
    <label
      v-if="label"
      :for="id"
      :class="['label', labelClass]"
      @click="handleClickLabel"
    >
      {{ label }}
    </label>
    <p
      v-if="hint"
      class="helper-text text-xs text-regular"
    >
      {{ hint }}
    </p>
    <div class="text-area-wrapper">
      <textarea
        :id="id"
        ref="inputElement"
        :rows="rows"
        :value="modelValue"
        :readonly="readonly"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="['text-area text-regular', { '--error': isError, 'resize': resizable }, fontSizeClass]"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="emit('change')"
      />
    </div>
    <p
      v-if="!isShowCustomError && isError"
      class="error-message text-sm text-regular"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.input-container {
	display: flex;
	flex-direction: column;

	.label {
		margin-bottom: spacings-get(1);
	}

	.text-area-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.text-area {
		position: relative;
		padding: spacings-get(3) rem(14);
		width: 100%;
		@include fonts-family;
		color: colors-get(gray, 900);
		background: colors-get(base, white);
		outline: none;
		border: 1px solid colors-get(gray, 300);
		@include border-radius-default;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		box-sizing: border-box;

		&:not(.resize) {
			resize: none;
		}

		&:focus {
			color: colors-get(gray, 900);
			border: 1px solid colors-get(primary, 300);
			box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:disabled {
			background: colors-get(gray, 50);
		}

		&::placeholder {
			color: colors-get(gray, 400);
		}

		&.--error {
			border: 1px solid colors-get(error, 300);

			&:focus {
				box-shadow: 0px 0px 0px 4px #{colors-get(error, 100)}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			}
		}
	}

	.helper-text {
		margin-bottom: spacings-get(3);
		color: colors-get(gray, 700);
	}
}
</style>