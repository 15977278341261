<script setup lang="ts">
const props = defineProps({
	link: {
		type: String,
		default: '',
	},
	status: {
		type: String,
		default: '',
	},
	isHappeningNow: {
		type: Boolean,
		default: false,
	},
	isEventPage: {
		type: Boolean,
		default: false,
	},
});

const { isMobile } = useWindowResize();

const isPublished = computed(() => props.status === EVENT_STATUS.OPEN || EVENT_STATUS.SOLD_OUT);

const buttonText = computed(() => {
	if (isPublished.value && props.isHappeningNow) {
		return 'Join';
	} else if (isPublished.value && !props.isHappeningNow) {
		return 'Starts soon';
	}
	return '';
});

const buttonSize = computed(() => {
	if (!props.isEventPage) {
		return BtnSize[0];
	}
	return isMobile.value ? BtnSize[0] : BtnSize[1];
});
</script>
<template>
  <BaseAlert
    :class="['virtual-link-alert', { '--desktop': !isMobile && isEventPage }]"
    :type="ALERT_TYPE.PRIMARY"
    hide-icon
  >
    <slot />
    <a
      v-if="link"
      class="join-btn"
      :href="link"
      target="_blank"
    >
      <BaseButton
        variant="solid"
        color="primary"
        :size="buttonSize"
        :disabled="!isHappeningNow"
      >
        <template #leftIcon>
          <VideoRecorderIcon />
        </template>
        {{ buttonText }}
      </BaseButton>
    </a>
  </BaseAlert>
</template>
<style lang="scss" scoped>
.virtual-link-alert {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(3);

  &.--desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .join-btn {
    margin-left: spacings-get(8);
    width: fit-content;
  }
}
</style>
