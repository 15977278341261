<script setup lang="ts">
// @ts-ignore
// eslint-disable-next-line camelcase
import { atcb_action } from 'add-to-calendar-button';
import type { TicketInfo } from '@/types/profile';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';

const props = defineProps({
	data: {
		type: Object as PropType<TicketInfo | null>,
		required: true,
		default: null,
	},
	isOpen: {
		type: Boolean,
		required: true,
		default: false,
	},
	hasOwnership: {
		type: Boolean,
		required: true,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onCancel'): void
	(e: 'onSuccess'): void
}>();

const showMap = ref(false);
const showAddCalendarDialog = ref(false);
const btnAddGoogleCalendar = ref();

const eventType = computed(() => props.data?.event?.locationType);
const eventStatus = computed(() => props.data?.event?.status);

const isPublished = computed(() => {
	return eventStatus.value === EVENT_STATUS.OPEN ||
  eventStatus.value === EVENT_STATUS.SOLD_OUT;
});

const isDraft = computed(() => {
	return eventStatus.value === EVENT_STATUS.DRAFT;
});

const alertEventMessage = computed(() => {
	if (eventStatus.value === EVENT_STATUS.CANCELED) {
		return 'This event has been canceled.';
	}

	if (eventStatus.value === EVENT_STATUS.FINISHED) {
		return 'This event has finished.';
	}

	return '';
});

const displayDateTime = computed(() => {
	if (!props.data) {
		return '';
	}

	return convertToDisplayStartAndEndDateTime(
		props.data?.startDateTime,
		props.data?.endDateTime,
		props.data?.timezone,
	);
});

const toggleMapText = computed(() => {
	return showMap.value ? 'Hide map' : 'Show map';
});

const chevronIconComponent = computed(() => (showMap.value ? ChevronUpIcon : ChevronDownIcon));

const isShowAction = computed(() => {
	return isPublished.value || isDraft.value;
});

const isShowMapSection = computed(() => {
	return props.data?.coordinate && isShowAction.value;
});

function handleAddToGoogleCalendar(calendarType: string) {
	if (!props.data) {
		return;
	}

	// https://add-to-calendar-button.com/advanced-use#case-10
	const startDate = convertDateTimeFromUtcToTimezone(props.data?.event.start.utc, props.data?.event.start.timezone, AMERICAN_DATE_FORMAT);
	const startTime = convertDateTimeFromUtcToTimezone(props.data?.event.start.utc, props.data?.event.start.timezone, TIME_24_HOUR_FORMAT);
	const endDate = convertDateTimeFromUtcToTimezone(props.data?.event.end.utc, props.data?.event.end.timezone, AMERICAN_DATE_FORMAT);
	const endTime = convertDateTimeFromUtcToTimezone(props.data?.event.end.utc, props.data?.event.end.timezone, TIME_24_HOUR_FORMAT);
	const config = {
		name: props.data?.event?.name,
		description: props.data?.event?.description, // TODO: add description
		startDate,
		startTime,
		endDate,
		endTime,
		options: [calendarType],
		timeZone: props.data?.event.start.timezone,
		iCalFileName: props.data?.event?.name,
		...(eventType.value === EVENT_TYPE.PHYSICAL && {
			location: props.data?.event?.locationSettings.venueName,
		}),
	};
	atcb_action(config, btnAddGoogleCalendar.value);
	showAddCalendarDialog.value = false;
}

watch(() => props.isOpen, (newValue) => {
	if (newValue) {
		showMap.value = false;
		showAddCalendarDialog.value = false;
	}
});
</script>
<template>
  <BaseSideDrawer
    :is-open="isOpen"
    @on-close="emit('onClose')"
  >
    <template #header>
      <div class="ticket-detail-header">
        <TicketIcon />
        <h1 class="text-xl text-semibold">
          View ticket
        </h1>
      </div>
    </template>
    <template #content>
      <div class="ticket-detail-content">
        <BaseAlert
          v-if="!!alertEventMessage"
          class="event-alert"
          :type="ALERT_TYPE.INFO"
        >
          <span class="text-sm text-semibold">
            {{ alertEventMessage }}
          </span>
        </BaseAlert>
        <p
          v-if="data?.event?.name"
          class="text-md text-medium"
        >
          {{ data?.event?.name }}
        </p>
        <template v-if="hasOwnership && isPublished">
          <VirtualLinkAlert
            v-if="eventType === EVENT_TYPE.ONLINE"
            class="virtual-box"
            :link="data?.event?.streamingSettings?.url"
            :status="data?.event?.status"
            :is-happening-now="data?.isHappeningNow"
          >
            <div class="content-container">
              <p class="text-sm text-regular">
                {{ data?.event?.streamingSettings?.attendeeInfo }}
              </p>
            </div>
          </VirtualLinkAlert>
          <QrcodeVue
            v-else-if="eventType === EVENT_TYPE.PHYSICAL"
            class="qrcode-box"
            :value="data?.qrcode"
            :size="148"
            level="Q"
          />
        </template>
        <div class="datetime-container">
          <div class="datetime-header">
            <h2 class="section-header text-sm text-medium">
              Date & time
            </h2>
            <BaseChip v-if="data?.isHappeningNow && isPublished">
              Happening now
            </BaseChip>
          </div>
          <template v-if="displayDateTime">
            <p
              class="text-sm text-regular pre-line"
            >
              {{ displayDateTime }}
            </p>
            <BaseButton
              v-if="isPublished"
              class="link"
              variant="link"
              size="sm"
              @click="showAddCalendarDialog = true"
            >
              Add to calendar
            </BaseButton>
            <button
              ref="btnAddGoogleCalendar"
              hidden
            />
          </template>
        </div>
        <div class="location-container">
          <h2 class="section-header text-sm text-medium">
            Location
          </h2>
          <template v-if="data?.event?.locationType === EVENT_TYPE.PHYSICAL && data?.event?.locationSettings?.venueName">
            <p class="text-sm text-regular">
              {{ data?.event?.locationSettings?.venueName }}
            </p>
            <template v-if="isShowMapSection">
              <BaseGoogleMap
                v-if="showMap"
                :coordinate="data?.coordinate"
                height="191"
              />
              <BaseButton
                class="link"
                variant="link"
                size="sm"
                @click="showMap = !showMap"
              >
                {{ toggleMapText }}
                <template #rightIcon>
                  <component
                    :is="chevronIconComponent"
                    color="#444CE7"
                    width="16"
                    height="16"
                  />
                </template>
              </BaseButton>
            </template>
          </template>
          <template v-if="data?.event?.locationType === EVENT_TYPE.ONLINE">
            <div class="online-container">
              <p class="text-sm text-regular">
                Online event
              </p>
            </div>
          </template>
        </div>
        <div class="tickets-container">
          <h2 class="section-header text-sm text-medium">
            Your tickets
          </h2>
          <div class="ticket-list">
            <div
              v-for="(ticket, index) in data?.orders"
              :key="index"
              class="ticket"
            >
              <p class="section-header text-sm text-medium">
                Order {{ ticket.id }}
              </p>
              <TicketDetailItem :data="ticket.attendances" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseSideDrawer>
  <AddEventToCalendarDialog
    :is-show="showAddCalendarDialog"
    @on-add-google-calendar="handleAddToGoogleCalendar(EVENT_CALENDAR_TYPE.GOOGLE)"
    @on-download-calendar="handleAddToGoogleCalendar(EVENT_CALENDAR_TYPE.ICAL)"
    @on-close="showAddCalendarDialog = false"
  />
</template>
<style scoped lang="scss">
.ticket-detail {
  &-header {
    display: flex;
    align-items: center;
    gap: spacings-get(2)
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: spacings-get(4);

    .virtual-box {
      margin: spacings-get(4) 0;

      :deep(.join-btn) {
        margin-left: 0;
      }
    }

    .qrcode-box {
      margin: auto;
      padding: spacings-get(4);
    }

    .section-header {
      color: colors-get(gray, 900);
    }

		.datetime-container,
		.location-container,
    .tickets-container {
      display: flex;
      flex-direction: column;
      row-gap: spacings-get(2);
      padding-bottom: spacings-get(4);
      color: colors-get(gray, 700);

      .datetime-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:not(:last-child) {
        border-bottom: 1px solid colors-get(gray, 300);
      }

      .link {
        width: fit-content;
        justify-content: start;
        gap: rem(2);
        padding-left: 0;
      }

      .ticket-list {
        display: flex;
        flex-direction: column;
        row-gap: spacings-get(3);

        .ticket {
          display: flex;
          flex-direction: column;
          row-gap: spacings-get(2);
          padding: spacings-get(3);
          @include border-radius-default;
          background-color: colors-get(gray, 50);
        }
      }
    }
  }
}
</style>