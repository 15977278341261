<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M11.5 4H8.3C6.61984 4 5.77976 4 5.13803 4.32698C4.57354 4.6146 4.1146 5.07354 3.82698 5.63803C3.5 6.27976 3.5 7.11984 3.5 8.8V16.2C3.5 17.8802 3.5 18.7202 3.82698 19.362C4.1146 19.9265 4.57354 20.3854 5.13803 20.673C5.77976 21 6.61984 21 8.3 21H15.7C17.3802 21 18.2202 21 18.862 20.673C19.4265 20.3854 19.8854 19.9265 20.173 19.362C20.5 18.7202 20.5 17.8802 20.5 16.2V13M20.6213 3.87868C21.7929 5.05025 21.7929 6.94975 20.6213 8.12132C19.4497 9.29289 17.5503 9.29289 16.3787 8.12132C15.2071 6.94975 15.2071 5.05025 16.3787 3.87868C17.5503 2.70711 19.4497 2.70711 20.6213 3.87868Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
