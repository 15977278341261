<template>
  <Error>
    <h1 class="context text-semibold">
      We <span class="text-highlight">can’t find</span> the page you’re looking for.
    </h1>
    <div>
      <p class="sub-context text-regular">
        Sorry, the page you are looking for doesn't exist or has been removed.
      </p>
      <p class="sub-context text-regular">
        Follow these
        <a
          href="https://help-attendee.peatix.com/en/support/solutions/articles/44002540319?_gl=1*zl25iw*_gcl_au*NjUwOTg5Mzk5LjE3MjcxMDczNjY"
          target="_blank"
        >
          steps
        </a>
        to access Japan events and tickets.
      </p>
    </div>
  </Error>
</template>