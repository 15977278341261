export enum USER_EVENT_ATTENDING_FILTER {
	UPCOMING = 'upcoming',
	ARCHIVED = 'archived',
}

export enum USER_EVENT_HOSTING_FILTER {
	UPCOMING = 'upcoming',
	ARCHIVED = 'archived',
	ACTIVE = 'active',
}

export enum MY_ACCOUNT_TABS {
	ATTENDING = 'attending',
	HOSTING = 'hosting',
}

export enum USER_EVENT_SORTING {
	SORT_DESC = 'desc(event.datetime)',
	SORT_ASC = 'asc(event.datetime)',
}

export enum SOCIAL_NAME {
	FACEBOOK = 'facebook',
	X = 'x',
	INSTAGRAM = 'instagram',
	TIKTOK = 'tiktok',
	YOUTUBE = 'youtube',
	LINKEDIN = 'linkedin',
	WEBSITE = 'website',
}

export enum SOCIAL_PREFIX_LINK {
	FACEBOOK = 'facebook.com/',
	X = 'twitter.com/',
	INSTAGRAM = 'instagram.com/',
	TIKTOK = 'tiktok.com/@',
	YOUTUBE = 'youtube.com/@',
	LINKEDIN = 'linkedin.com/in/',
	WEBSITE = 'https://',
}