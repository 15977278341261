<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '24',
	},
	height: {
		type: String,
		default: '24',
	},
});

const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M15.5505 9H6C4.61929 9 3.5 7.88071 3.5 6.5C3.5 5.11929 4.61929 4 6 4H15.5505M9.44949 20H19C20.3807 20 21.5 18.8807 21.5 17.5C21.5 16.1193 20.3807 15 19 15H9.44949M3.5 17.5C3.5 19.433 5.067 21 7 21C8.933 21 10.5 19.433 10.5 17.5C10.5 15.567 8.933 14 7 14C5.067 14 3.5 15.567 3.5 17.5ZM21.5 6.5C21.5 8.433 19.933 10 18 10C16.067 10 14.5 8.433 14.5 6.5C14.5 4.567 16.067 3 18 3C19.933 3 21.5 4.567 21.5 6.5Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
