<script setup lang="ts">
const props = defineProps({
	page: {
		type: Number,
		default: 1,
	},
	totalPages: {
		type: Number,
		default: 1,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onPageChange', page: number): void
}>();
const { isTablet } = useWindowResize();

const currentPage = computed({
	get: () => props.page,
	set: (page: number) => {
		emit('onPageChange', page);
	},
});

const isEmptyData = computed(() => props.totalPages === 0);
const isDisabledPrevButton = computed(() => currentPage.value === 1);
const isDisabledNextButton = computed(() => {
	const isLastPage = currentPage.value === props.totalPages;
	return isEmptyData.value || isLastPage;
});

function updateCurrentPage(direction: string) {
	let newPage = currentPage.value;
	if (direction === 'increment' && !isDisabledNextButton.value) {
		newPage = currentPage.value + 1;
	} else if (direction === 'decrement' && !isDisabledPrevButton.value) {
		newPage = currentPage.value - 1;
	}

	emit('onPageChange', newPage);
}

</script>
<template>
  <div class="pagination-container">
    <VPagination
      v-if="!isTablet"
      v-model="currentPage"
      rounded="circle"
      :length="totalPages"
      :total-visible="6"
    >
      <template #prev>
        <BaseButton
          variant="link"
          color="gray"
          :disabled="isLoading || isDisabledPrevButton"
          @click="updateCurrentPage('decrement')"
        >
          <ArrowLeftIcon />
          <span>Previous</span>
        </BaseButton>
      </template>
      <template #next>
        <BaseButton
          variant="link"
          color="gray"
          :disabled="isLoading || isDisabledNextButton"
          @click="updateCurrentPage('increment')"
        >
          <span>Next</span>
          <ArrowRightIcon />
        </BaseButton>
      </template>
    </VPagination>
    <div
      v-else
      class="mobile-pagination"
    >
      <BaseButton
        variant="link"
        color="gray"
        size="sm"
        :disabled="isLoading || isDisabledPrevButton"
        @click="updateCurrentPage('decrement')"
      >
        <ArrowLeftIcon />
        <span>Previous</span>
      </BaseButton>
      <div
        v-if="!isEmptyData"
        class="pagination-info text-sm"
      >
        <p>Page <span>{{ page }}</span> of <span>{{ totalPages }}</span></p>
      </div>
      <BaseButton
        variant="link"
        color="gray"
        size="sm"
        :disabled="isLoading || isDisabledNextButton"
        @click="updateCurrentPage('increment')"
      >
        <span>Next</span>
        <ArrowRightIcon />
      </BaseButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.pagination-container {
    margin-top: spacings-get(5);

    button {
      padding: 0;
    }

    .mobile-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: rem(90);
        display: flex;
        justify-content: flex-end;
      }

      .pagination-info span {
        @include fonts-weight(medium);
      }
    }
  }
</style>