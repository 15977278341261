<script setup lang="ts">
import type { SignInServiceType } from '@/types/auth';
import AppleCircleIcon from '@/components/icons/AppleCircleIcon.vue';
import GoogleIcon from '@/components/icons/GoogleIcon.vue';

const props = defineProps({
	type: {
		type: String as PropType<SignInServiceType>,
		required: true,
	},
});

defineEmits<{
	(e: 'onConfirm'): void;
	(e: 'onCancel'): void;
}
>();

const snsIconComponent = computed(() => (props.type === AUTH_SERVICE.APPLE ? AppleCircleIcon : GoogleIcon));
</script>

<template>
  <div class="auth-page-container">
    <div class="sns-connect-logo-wrapper">
      <PeatixIcon />
      <LinkIcon
        class="link-logo"
        :color="ICON_COLOR.WHITE"
      />
      <component
        :is="snsIconComponent"
        width="48"
        height="48"
      />
      <VectorIcon class="connect-line-logo" />
    </div>
    <div
      class="text-align-center text-md text-regular"
    >
      An account linked to this email already exists. Verify the code to connect with your {{ type }} account.
    </div>
    <div class="action">
      <BaseButton
        variant="solid"
        color="primary"
        size="lg"
        @click="$emit('onConfirm')"
      >
        Email me a verification code
      </BaseButton>
      <BaseButton
        variant="outlined"
        color="gray"
        size="lg"
        @click="$emit('onCancel')"
      >
        Cancel
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action {
  display: flex;
  flex-direction: column;
  gap: spacings-get(4);
  width: 100%;
}

.sns-connect-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(14);

  .link-logo {
    padding: rem(6);
    @include border-radius-rounded;
    background-color: colors-get(primary, 600);
    border: 3px solid colors-get(primary, 100);
    box-sizing: content-box;
  }

  .connect-line-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}
</style>