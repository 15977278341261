import type { ErrorResponse } from '@/types/common';
import type { UserNotificationSetting } from '@/types/user';

export const getNotificationSettingAPI = (userId: number) => {
	return useRequest<UserNotificationSetting, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/notifications`, REQUEST_METHOD.GET);
};

export const postNotificationSettingAPI = (userId: number, payload: UserNotificationSetting) => {
	return useRequest<UserNotificationSetting, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/notifications`, REQUEST_METHOD.POST, {
		...payload,
	});
};