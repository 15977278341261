export enum ATTENDEE_SORTING_METHOD {
	LATEST_ADDED = 'desc(sales.created)',
	OLDEST_ADDED = 'asc(sales.created)',
	ALPHABETICAL_A2Z = 'asc(sales.name)',
	ALPHABETICAL_Z2A = 'desc(sales.name)',
}

export enum ATTENDEE_FILTER_STATUS {
	PENDING_ATTENDENCE = 'sales.status:paid AND attendance.status:waiting',
	CHECKED_IN = 'attendance.status:checked_in',
	NO_SHOW = 'attendance.status:skipped',
	CANCELED = 'sales.status:void,refund',
}

export enum ATTENDEE_STATUS {
	WAITING = 'waiting',
	CANCELED = 'canceled',
	CHECKED_IN = 'checked_in',
	SKIPPED = 'skipped',
}

export enum ATTENDEE_SALES_STATUS {
	PAID = 'paid',
	ON_HOLD = 'on_hold',
	VOID = 'void',
	REFUND = 'refund',
	SOLD = 'sold',
}

export enum ATTENDEE_ORDER_STATUS {
	PENDING_ATTENDENCE = 'pending',
	CANCELED = 'canceled',
	CHECKED_IN = 'checked_in',
	NO_SHOW = 'no_show',
}

export enum ATTENDEE_SEARCH_KEY {
	NAME = 'name',
	STATUS = 'status',
}

export enum ATTENDEE_CHECK_IN_STATUS {
	CHECK_IN = 'checkin',
	CHECK_OUT = 'checkout',
}

export enum ATTENDEE_MESSAGE_TYPE {
	TO_SPECIFIC = 1,
	TO_ALL = 2,
}