<script setup lang="ts">
import type { PropType } from 'vue';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import type { StripeRequirement } from '@/types/connect';

const props = defineProps({
	currentStep: {
		type: Number,
		default: 0,
		required: true,
	},
	requirements: {
		type: Object as PropType<StripeRequirement>,
		default: () => {},
	},
	status: {
		type: String,
		default: undefined,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const defaultSteps = [
	{
		step: 1,
		header: 'Additional details required',
		description: 'Please complete the following Stripe requirements for verification.',
	},
];

const modifiedRequirements = computed(() => {
	if (!props.requirements) {
		return [];
	}

	const transformed: string[] = [];
	// Prioritizing 'past_due' requirements ensures urgent overdue tasks are addressed promptly,
	// maintaining compliance and preventing disruptions.
	props.requirements?.pastDue?.forEach((topic) => {
		const items = topic.split('.');
		const modifiedItem = items.map((item) => convertToCapitalCase(item)).join(' - ');
		transformed.push(modifiedItem);
	});
	return transformed.sort();
});

const statusText = computed(() => {
	switch (props.status) {
		case STRIPE_CONNECT_STATUS.INCOMPLETE:
			return 'Incomplete';
		case STRIPE_CONNECT_STATUS.IN_REVIEW:
			return 'In-review';
		default:
			return '';
	}
});
</script>
<template>
  <VTimeline
    class="stripe-timeline"
    align="start"
    side="end"
    truncate-line="both"
    line-inset="4"
  >
    <VTimelineItem
      v-for="(step, stepIndex) in defaultSteps"
      :key="stepIndex"
      :class="['stripe-timeline-item', {
        '--active': status === STRIPE_CONNECT_STATUS.INCOMPLETE,
        '--finished': status === STRIPE_CONNECT_STATUS.SUCCEEDED,
      }]"
      size="24px"
      dot-color="#EAECF0"
      icon-color="#444CE7"
      :icon="status === STRIPE_CONNECT_STATUS.SUCCEEDED ? CheckIcon : null"
    >
      <div class="timeline-container">
        <h3 class="timeline-header">
          {{ step.header }}
        </h3>
        <div class="additional-detail">
          <BaseChip
            v-if="statusText"
            class="connect-status"
            :color="CHIP_COLOR.WARNING"
          >
            {{ statusText }}
          </BaseChip>
          <div class="connect-info">
            <p>{{ step.description }}</p>
            <ul
              v-if="modifiedRequirements.length"
              class="connect-info-list"
            >
              <li
                v-for="(requirement, index) in modifiedRequirements"
                :key="index"
              >
                {{ requirement }}
              </li>
            </ul>
            <ul
              v-else
              class="connect-info-list"
            >
              <li>-</li>
            </ul>
          </div>
        </div>
      </div>
    </VTimelineItem>
  </VTimeline>
</template>
<style scoped lang="scss">
.stripe-timeline {
  grid-template-columns: 0px min-content auto !important;
  @include fonts-get(regular, text-sm);
  color: colors-get(gray, 600);
  margin-bottom: spacings-get(4);

  .stripe-timeline-item {
    .timeline-container {
      display: flex;
      flex-direction: column;
    }

    .timeline-header {
      @include fonts-get(semibold, text-sm);
      color: colors-get(gray, 700);
    }

    :deep(.v-timeline-divider__dot) {
      background-color: transparent;
      border: 2px solid colors-get(gray, 200);
      box-shadow: none !important;
    }

    :deep(.v-timeline-divider__inner-dot) {
      width: spacings-get(2);
      height: spacings-get(2);
    }

    .additional-detail {
      display: flex;
      flex-direction: column;

      .connect-status {
        margin: spacings-get(1) 0;
      }

      .connect-info {
        margin-top: spacings-get(4);
      }

      .connect-info-list {
        margin-top: spacings-get(2);
        margin-left: spacings-get(4);
      }
    }

    &.--active {
      :deep(.v-timeline-divider__dot) {
        background-color: colors-get(primary, 50);
        border: 2px solid colors-get(primary, 600);
        box-shadow: 0px 0px 0px 4px #F4EBFF !important;
      }

      :deep(.v-timeline-divider__inner-dot) {
        background-color: colors-get(primary, 600) !important;
      }

      .timeline-header {
        color: colors-get(primary, 700);
      }
    }

    &.--finished {
      :deep(.v-timeline-divider__dot) {
        background-color: colors-get(primary, 50);
        border: 2px solid colors-get(primary, 600);
      }

      :deep(.v-icon) {
        font-size: spacings-get(3) !important;
        height: spacings-get(3) !important;
        width: spacings-get(3) !important;
      }

      :deep(path) {
        stroke-width: 3;
      }
    }
  }
}
</style>