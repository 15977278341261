<script setup lang="ts">
import VOtpInput from 'vue3-otp-input';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';

const CODE_VALIDATION_MESSAGE = 'Please enter your 6-digit code.'; // TODO: Remove after add i18n

const props = defineProps({
	confirmButtonName: {
		type: String,
		default: null,
	},
	serverError: {
		type: String,
		default: null,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
});

const rules = {
	code: {
		required: helpers.withMessage(CODE_VALIDATION_MESSAGE, required),
		minLength: helpers.withMessage(CODE_VALIDATION_MESSAGE, minLength(6)),
	},
};

const emit = defineEmits<{
	(e: 'onResendCode'): void;
	(e: 'onSubmitCode', code: string): void;
}
>();

const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);
const code = ref('');
const codeMessage = ref('');
const v$ = useVuelidate(rules, { code });
const isSentCode = ref(false);

function handleSubmit() {
	v$.value.$touch();
	if (v$.value.$error) {
		codeMessage.value = getValidationErrorMessage(v$.value.code.$errors);
		return;
	}

	emit('onSubmitCode', code.value);
}

function handleOnComplete(value: string) {
	codeMessage.value = '';
	code.value = value;
}

function handleChange(value: string) {
	if (v$.value.code.$error) {
		v$.value.code.$reset();
	}

	code.value = value;
}

function handleResendCode() {
	isSentCode.value = true;
	otpInput.value?.clearInput();
	emit('onResendCode');
}

const inputClasses = computed(() => {
	const classes = ['otp-input'];
	if (codeMessage.value) {
		classes.push('error');
	}
	return classes;
});

const resendCodeText = computed(() => {
	if (props.isLoading) {
		return 'Resending';
	} else if (!props.isLoading && isSentCode.value) {
		// Delay after the code has been resend to slowly transit from `Sent` -> 'Resend Code'
		setTimeout(() => {
			isSentCode.value = false;
		}, 2000);
		return 'Sent!';
	}

	return 'Resend Code';
});

const isLoadingState = computed(() => {
	return props.isLoading || isSentCode.value;
});

onUnmounted(() => {
	// Reset form validation
	v$.value.$reset();
});

watch(() => props.serverError, (serverErrorMessage) => {
	codeMessage.value = serverErrorMessage;
});
</script>

<template>
  <div class="auth-page-container">
    <div class="instruction">
      <slot name="header" />
      <slot name="content" />
    </div>
    <form
      class="form-container"
      @submit.prevent="handleSubmit"
    >
      <div class="otp-container">
        <VOtpInput
          ref="otpInput"
          :input-classes="inputClasses"
          input-type="number"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          @on-complete="handleOnComplete"
          @on-change="handleChange"
        />
      </div>
      <p
        v-if="codeMessage"
        class="error-message text-sm text-regular text-align-center"
      >
        {{ codeMessage }}
      </p>
      <div class="btn-wrapper">
        <BaseButton
          variant="solid"
          color="primary"
          size="lg"
          type="submit"
          :disabled="isLoadingState"
        >
          {{ confirmButtonName || 'Continue' }}
        </BaseButton>
        <BaseButton
          variant="outlined"
          color="gray"
          size="lg"
          :disabled="isLoadingState"
          @click="handleResendCode"
        >
          {{ resendCodeText }}
        </BaseButton>
        <slot name="action" />
      </div>
    </form>
    <slot name="footer" />
  </div>
</template>

<style lang="scss" scoped>
.otp-container > div {
  justify-content: space-between;
}
</style>