interface LayoutSettingStore {
	isGeolocationChecked: boolean
	hasActionBar: boolean
	isPublic: boolean // Flag to define if page is for user or organizer
	isFullScreen: boolean
}

const useLayoutStore = defineStore('layout', {

	state: (): LayoutSettingStore => ({
		isGeolocationChecked: false,
		hasActionBar: false,
		isPublic: false,
		isFullScreen: false,
	}),
});

export default useLayoutStore;
