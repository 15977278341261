<script setup lang="ts">
import type { PaymentCard } from '@/types/common';
import dayjs from 'dayjs';

defineProps({
	card: {
		type: Object as PropType<PaymentCard>,
		required: true,
		default: () => {},
	},
});

function formatExpiryDate(date: string) {
	const originalDate = dayjs(date, EXPIRY_DATE_FORMAT);
	const formattedDate = originalDate.format(SHORTEN_EXPIRY_DATE_FORMAT);
	return formattedDate;
}
</script>

<template>
  <div class="credit-card-info">
    <p class="card-number text-sm text-medium">
      {{ `**** **** **** ${card.last4}` }}
    </p>
    <p
      :class="['card-expiry text-sm text-regular', {
        '--expired': card.isExpired
      }]"
    >
      {{ card.isExpired ? 'Expired' : `Expiration ${formatExpiryDate(card.expires)}` }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.credit-card-info {
	.card-number {
		color: colors-get(gray, 900);
	}

	.card-expiry {
		color: colors-get(gray, 600);

		&.--expired {
			color: colors-get(error, 600);
		}
	}
}
</style>