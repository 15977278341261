export enum CHECKOUT_PROCESS_STATUS_TEMPLATE {
	PAYMENT_SUCCESS,
	PAYMENT_FAILED,
	PAYMENT_SOMETHING_WENT_WRONG,
}

export enum PAYMENT_METHOD {
	CREDIT_CARD = 'card',
	APPLE_PAY = 'apple',
	GOOGLE_PAY = 'google',
}

export enum CHECKOUT_ORDER_STATUS {
	PROCESSING = 'processing',
	FULFILLED = 'fulfilled',
}

export enum PAYMENT_SELECTION_TABS {
	SAVE_CARDS,
	USE_NEW_CARD,
}

export enum CARD_BRAND {
	VISA = 'visa',
	MASTERCARD = 'mastercard',
	AMEX = 'amex',
	DISCOVER = 'discover',
	DINERS = 'diners',
	JCB = 'jcb',
	UNIONPAY = 'unionpay',
}

export enum TICKET_SELECTION_MODE {
	TICKETS,
	TOKEN_GATE,
}

export enum POLLING_ORDER_STATUS {
	SUCCESS,
	FAIL,
}