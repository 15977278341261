<script setup lang="ts">
defineProps({
	height: {
		type: String,
		default: '40',
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const slots = defineSlots();
const hasSlotContent = computed(() => !!slots.default);
</script>

<template>
  <template v-if="hasSlotContent">
    <VSkeletonLoader
      :loading="loading"
      type="image"
      :height="height"
    >
      <slot />
    </VSkeletonLoader>
  </template>
  <template v-else>
    <VSkeletonLoader
      :loading="loading"
      type="image"
      :height="height"
    />
  </template>
</template>

<style scoped lang="scss">
:deep(.v-skeleton-loader__bone) {
  height: inherit;
}
</style>
