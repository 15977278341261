import type { Country, CountryResponse, Timezone, TimezoneResponse } from '@/types/country-timezone';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

export const transformTimezone = (timezone: TimezoneResponse, dateTime = ''): Timezone => {
	const dateTimeObject = dateTime ? dayjs(dateTime) : dayjs();
	const offset = dateTimeObject.tz(timezone.name).format('Z');
	const timezoneName = timezone.name.split('/');
	const cityNames = timezoneName.slice(1).join(', ').replaceAll('_', ' ');
	return {
		name: cityNames,
		timezone: timezone.name,
		timezoneOffset: offset,
	};
};

export const transformTimezones = (timezones: TimezoneResponse[], dateTime = ''): Timezone[] => timezones.map((timezone) => transformTimezone(timezone, dateTime));

export const transformCountries = (countries: CountryResponse[]): Country[] => {
	const sortedCountry = sortBy(countries, ['name']);
	return sortedCountry.map((country: CountryResponse): Country => {
		return {
			id: country.id,
			name: country.name,
			countryCode: country.countryCode,
		};
	});
};
