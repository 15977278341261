import type { Country, CountryResponse } from '@/types/country-timezone';

interface CountryStore {
	countries: CountryResponse[]
	isLoading: boolean
	errorMessage: string,
}

const useCountryStore = defineStore('country', {

	state: (): CountryStore => ({
		countries: [],
		isLoading: false,
		errorMessage: '',
	}),

	getters: {
		countryList: (state: CountryStore): Country[] => {
			if (!state.countries.length) {
				return [];
			}
			return transformCountries(state.countries);
		},
	},

	actions: {
		async getCountries() {
			this.isLoading = true;
			const { data, errorMessage } = await getCountriesAPI();

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.countries = data.value.data;
			}
			this.isLoading = false;
		},
	},
});

export default useCountryStore;
